import React from 'react';
import MenuListComp from '../../coreUI/menuComp/MenuListComp';
import { languageMenu } from './fixedData';
// import { changeLanguage } from 'i18next';
import { useTranslation } from 'react-i18next';

type Props = {};

const LanguageBtnComp = (props: Props) => {
  const { t, i18n } = useTranslation('common');

  const changeLanguage = (language: 'de' | 'fr' | 'en') => {
    if (language === 'de') {
      i18n.changeLanguage(language);
      localStorage.setItem('i18nextLng', 'de');
    } else if (language === 'en') {
      i18n.changeLanguage(language);
      localStorage.setItem('i18nextLng', 'en');
    } else if (language === 'fr') {
      i18n.changeLanguage(language);
      localStorage.setItem('i18nextLng', 'fr');
    }
  };

  return (
    <MenuListComp
      list={languageMenu.list(changeLanguage)}
      menuView={languageMenu.menuView(t('nav.language'))}
      title={languageMenu.title}
      type="button"
      name={t('nav.language')}
    />
  );
};

export default LanguageBtnComp;
