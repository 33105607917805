import {
  GetHistoryInput,
  UpdateHistoryInput,
} from 'src/types/transHistory.types';
import http from '../config/httpComman';
import { InsertToString } from 'src/utils/insertToString';

export default class transHistoryService {
  static async getTransHistoryApi(data: GetHistoryInput) {
    let url = InsertToString(`job/all-paginated/`, data);
    return await http.get(url);
  }

  static async updateTransHistoryApi(data: UpdateHistoryInput) {
    const newData = {
      ...data,
    };
    const id = newData.id;
    delete newData.id;
    return await http.put(`job/web/${id}/`, newData);
  }

  static async deleteTransHistoryApi(id: string) {
    return await http.delete(`job/${id}/`);
  }
}
