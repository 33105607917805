import { createSelector } from 'reselect';
import { SeparatorValue, transConfigGet } from 'src/types/translation.types';

export interface TranslationReducer {
  loadingTrans: boolean;
  transjob: {
    simpleText: string;
    showModal: boolean;
    filterStatus: number;
    jobId: string;
    inputText: string;
    text_metrics: {
      input_lix_score: number;
      input_lix_score_display: string;
      output_lix_score: number;
      output_lix_score_display: string;
    };
    initial_text_complexity: number;
    final_text_complexity: number;
  };
  showRefreshPageAlert: boolean;
  separatorStyle: SeparatorValue;
  newLine: boolean;
  transConfig: null | transConfigGet;
  selectedOutputNode: null | string;
  emptyInput: boolean;
}
interface RootState {
  translation: TranslationReducer;
}

// This used to memorize the selector value
export const TranslateSelector = createSelector(
  (state: RootState) => state.translation,
  (config: TranslationReducer) => {
    return {
      loadingTrans: config.loadingTrans,
      transjob: config.transjob,
      showRefreshPageAlert: config.showRefreshPageAlert,
      separatorStyle: config.separatorStyle,
      newLine: config.newLine,
      transConfig: config.transConfig,
      selectedOutputNode: config.selectedOutputNode,
      emptyInput: config.emptyInput,
    };
  }
);
