import { useEffect } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import LanguageSwitchBtn from './LanguageSwitchBtn';
import logo from '../assets/logo_dark.svg';
import { useNavigate, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useSelector } from 'react-redux';
import { supabaseSelector } from 'src/redux/supabase/selector';
import SignupForm from './signupForm/SignupForm';

const SignupPage = () => {
  const { t } = useTranslation('common');
  const history = useNavigate();
  const supabaseUser = useSelector(
    (state: any) => supabaseSelector(state).user,
    shallowEqual
  );
  useEffect(() => {
    if (supabaseUser && !localStorage.getItem('signup_form')) {
      history('/');
    }
    //TODO: fix lint error with more knowledge
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supabaseUser]);

  return (
    <div>
      <Container>
        <Row>
          <Col>
            <Row className="mt-4">
              <LanguageSwitchBtn />
            </Row>
          </Col>
        </Row>
        <Row>
          <Col>
            <img
              className="pt-4 mb-5"
              src={logo}
              alt="Logo"
            />

            <SignupForm />

            <div className="w-100 text-center mt-2 mb-5">
              {t('signupForm.alreadyHaveAccount')}{' '}
              <Link to="/">{t('toLogin')}</Link>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default SignupPage;
