import React, { FC, useCallback, useEffect, useState } from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getUserDetails, updateUserInfo } from '../../redux/user/actions';
import { UserSelector } from '../../redux/user/selector';
import LoadingBanner from '../LoadingBanner';
import OptinWaitingSupabase from '../OptinWaitingSupabase';
import LogoutButton from '../utils/LogoutButton';
import { clearLocalStorage } from '../utils/utils';
import HelpSection from './HelpSection';
import UpdateAppPopup from './Modals/UpdateAppPopup';
import WhatsnewPopup from './Modals/WhatsnewPopup';
import BetaListWarning from './Warnings/BetaListWarning';
import LicenceExpiredWarning from './Warnings/LicenceExpiredWarning';
import ServerDownWarning from './Warnings/ServerDownWarning';
import { supabaseSelector } from 'src/redux/supabase/selector';
import { Container } from '@mui/material';
import { setMatomoUid } from 'src/utils/matomo';
import { newLineSet } from 'src/redux/translation/actions';
import { numberFormatter } from 'src/utils/formatNumber';
import { getShareGlossaryEntites } from 'src/redux/glossaryEntries/actions';

const Dashboard: FC = () => {
  const [showUpdateAppPopup, setShowUpdateAppPopup] = useState(false);
  const dispatch: any = useDispatch();
  const [modalShow, setModalShow] = useState(false);

  const { t } = useTranslation(['dashboard', 'glossary'], {
    nsMode: 'fallback',
  });
  const userDetails: any = useSelector(
    (state: any) => UserSelector(state).userDetails,
    shallowEqual
  );
  const loading = useSelector(
    (state: any) => UserSelector(state).loading,
    shallowEqual
  );

  const userExist = useSelector(
    (state: any) => UserSelector(state).userExist,
    shallowEqual
  );

  //   const { setuserDetails } = useAuth();
  const history = useNavigate();

  const [userRole, setUserRole] = useState(false);
  const supabaseSession = useSelector(
    (state: any) => supabaseSelector(state).session,
    shallowEqual
  );

  const supabaseUser = useSelector(
    (state: any) => supabaseSelector(state).user,
    shallowEqual
  );

  useEffect(() => {
    if (!supabaseSession?.access_token) {
      return;
    }
    if (!localStorage.getItem('signup_form') && userExist) {
      dispatch(getUserDetails());
    }

    newLineSet(localStorage.getItem('isNewLine') === 'false' ? false : true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [supabaseSession?.access_token, userExist]);

  const checkAppOutdated: any = useCallback(() => {
    // check if app needs to be updated
    if (userDetails && userDetails?.appversion) {
      let responseAppVersion = userDetails?.appversion;
      const appVersion = localStorage.getItem('appversion');
      if (appVersion !== null) {
        if (appVersion !== responseAppVersion) {
          setShowUpdateAppPopup(true);
        }
      } else {
        localStorage.setItem('appversion', responseAppVersion);
      }
    }
  }, [userDetails]);

  useEffect(() => {
    if (localStorage.getItem('IN_PURCHASE_FLOW') === 'true') {
      history('/checkout');
    }

    if (localStorage.getItem('signup_form')) {
      history('/signup');
    }

    // if (!userDetails) {
    //   localStorage.removeItem('token');
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (userDetails) {
      checkAppOutdated();
      setUserRole(userDetails?.userrole === 'admin');
      setMatomoUid(userDetails?.email);

      // pushInstruction('setUserId', userDetails?.email);
      setModalShow(!userDetails?.hasreadwhatsnew);
      dispatch(getShareGlossaryEntites());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userDetails]);

  const handleTranslateTextClick = () => {
    history('/easy-language');
  };

  const handleTranslationHistoryClick = () => {
    // trackEvent({
    //   category: 'Dashboard',
    //   action: 'click-translation-history',
    // });

    history('/translation-history');
  };

  const closeWhatsNewPopup = async (flag: boolean) => {
    dispatch(updateUserInfo({ hasreadwhatsnew: flag }));
    setModalShow(false);
  };

  const checkIfEmailIsNotVerified = () => {
    if (supabaseUser?.created_at) {
      return false;
    }
    return true;
  };
  return (
    <Container>
      <div>
        {loading ? (
          <LoadingBanner />
        ) : userDetails ? (
          checkIfEmailIsNotVerified() ? (
            <OptinWaitingSupabase />
          ) : userDetails?.betauser ? (
            userDetails?.hasactivelicence ? (
              <>
                <WhatsnewPopup
                  show={modalShow}
                  close={closeWhatsNewPopup}
                  onClick={() => closeWhatsNewPopup(true)}
                />

                <UpdateAppPopup
                  show={showUpdateAppPopup}
                  onClick={clearLocalStorage}
                />

                <h4 className="mt-5">
                  {t('headertext.welcome')}
                  <br /> <b>{supabaseUser?.email}</b>
                </h4>
                <h5>{t('headertext.whattodo')}</h5>

                <Row>
                  <Col
                    sm={{ span: 8, offset: 2 }}
                    md={{ span: 6, offset: 3 }}
                    lg={{ span: 6, offset: 3 }}
                    xl={{ span: 4, offset: 4 }}
                  >
                    <Button
                      variant="primary"
                      className="w-50 text-center mt-4"
                      type="submit"
                      onClick={handleTranslateTextClick}
                      style={{ textTransform: 'capitalize' }}
                    >
                      {t('buttons.newtranslation')}
                    </Button>
                    <div></div>
                    <Button
                      variant="outline-primary"
                      className="w-50 text-center mt-4"
                      type="submit"
                      onClick={handleTranslationHistoryClick}
                      style={{ textTransform: 'capitalize' }}
                    >
                      {t('buttons.translationhistory')}
                    </Button>
                    <div></div>
                    <Button
                      variant="outline-primary"
                      className="w-50 text-center mt-4"
                      type="submit"
                      onClick={() => history('/glossary-entries')}
                      style={{ textTransform: 'capitalize' }}
                    >
                      {t('buttons.glossaryentries')}
                    </Button>
                    <div></div>
                    {/* TODO: remove admin pages */}
                    {false && userRole ? (
                      <Button
                        variant="outline-primary"
                        className="w-50 text-center mt-4"
                        type="submit"
                        onClick={() => history('/admin')}
                      >
                        {t('buttons.admin')}
                      </Button>
                    ) : (
                      <></>
                    )}

                    <div className="mt-5">
                      <br />
                      {t('quotaused')}: <br />
                      {userDetails?.quotaspent
                        ? numberFormatter(userDetails?.quotaspent)
                        : '0'}{' '}
                      / {numberFormatter(userDetails?.quotalimit)} <br />
                      {userDetails?.hasindividualquota
                        ? ''
                        : `(${t('companyquota')})`}
                    </div>
                  </Col>
                </Row>
                <Row>
                  <Col
                    md={{ span: 4, offset: 4 }}
                    className="mt-5"
                  >
                    <LogoutButton />
                  </Col>
                </Row>
                <Row>
                  <Col
                    md={{ span: 4, offset: 4 }}
                    className="mt-2"
                  >
                    <HelpSection />
                  </Col>
                </Row>
              </>
            ) : (
              <LicenceExpiredWarning />
            )
          ) : (
            <BetaListWarning />
          )
        ) : (
          <ServerDownWarning />
        )}
      </div>
    </Container>
  );
};

export default Dashboard;
