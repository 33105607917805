import { Modal } from 'react-bootstrap';
import styles from './RequestReviewPopup.module.css';
import RequestReviewForm from '../Forms/RequestReviewForm';

const RequestReviewPopup = (props) => {
  return (
    <Modal
      show={props.show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => props.close()}
      backdrop="static"
      keyboard={false}
      style={{
        zIndex: 10000,
      }}
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body className={styles['modal-body-div']}>
        <RequestReviewForm />
      </Modal.Body>
    </Modal>
  );
};

export default RequestReviewPopup;
