import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { shallowEqual, useSelector } from 'react-redux';
import { supabaseSelector } from 'src/redux/supabase/selector';

export default function PrivateRoute() {
  const supabaseUser = useSelector(
    (state) => supabaseSelector(state).user,
    shallowEqual
  );

  return supabaseUser ? <Outlet /> : <Navigate to="/" />;
}
