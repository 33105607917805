import { SelectChangeEvent, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import styles from '../translationOutput/TranslationOutput.module.css';
import { appMessageHandling } from 'src/utils/errorHandler';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { languageType, newLanguage, transLangVal } from '../fixedData';
import { SettingComp } from '@summ-ai-github/summ-frontend-package';
import { useContext, useCallback, useEffect, useState } from 'react';
import {
  // separateStyleTrigger,
  setTranslationConfig,
  updateTranlationText,
} from 'src/redux/translation/actions';
import { TranslateSelector } from 'src/redux/translation/selector';
import { TranslationOutputContext } from '../translationOutput/TranslationOutput';

type Props = {};

const SettingCompWrapper = (props: Props) => {
  const { t } = useTranslation('easy_language');
  const [separator, setSeparator] = useState<{ plain: string; easy: string }>({
    plain: 'hyphen',
    easy: 'none',
  });
  const [newLine, setNewLine] = useState<{ plain: string; easy: string }>({
    plain: localStorage.getItem('newline.plain') || 'true',
    easy: localStorage.getItem('newline.easy') || 'true',
  });
  useEffect(() => {
    setSeparator({
      plain: localStorage.getItem('separator.plain') || 'hyphen',
      easy: localStorage.getItem('separator.easy') || 'none',
    });

    if (!localStorage.getItem('separator.plain')) {
      localStorage.setItem('separator.plain', 'hyphen');
    }

    if (!localStorage.getItem('separator.easy')) {
      localStorage.setItem('separator.easy', 'none');
    }
  }, []);
  const theme = useTheme();
  const dispatch: any = useDispatch();
  const { outputLanguage }: { outputLanguage: 'easy' | 'plain' } = useContext(
    TranslationOutputContext
  );
  const showFirstTimeUpdateSnackbar = useCallback(() => {
    if (localStorage.getItem('hasChangedPreference') !== 'true') {
      appMessageHandling(dispatch, t('setting.preferenceUpdated'), 'default');
      localStorage.setItem('hasChangedPreference', 'true');
    }
  }, [dispatch, t]);

  const separateType = useSelector(
    (state: any) => TranslateSelector(state).separatorStyle,
    shallowEqual
  );

  const transConfig = useSelector(
    (state: any) => TranslateSelector(state).transConfig,
    shallowEqual
  );

  const transjob = useSelector(
    (state: any) => TranslateSelector(state).transjob,
    shallowEqual
  );

  const handleSeparatorChange = useCallback(
    (e: SelectChangeEvent<any>) => {
      // attach the separator tyle to the selected language
      let langType = localStorage.getItem('outputLanguageType');
      localStorage.setItem(`separator.${langType}`, e?.target.value);
      setSeparator({
        ...separator,
        [outputLanguage]: e?.target.value,
      });

      if (transjob.jobId !== '-1') {
        dispatch(
          updateTranlationText({
            input_text: transjob.simpleText,
            separator: e?.target.value,
          })
        );
      }
      // showFirstTimeUpdateSnackbar();
    },
    [dispatch, outputLanguage, separator, transjob.jobId, transjob.simpleText]
  );

  const handleNewlineChange = useCallback(
    (e: SelectChangeEvent) => {
      let langType = localStorage.getItem('outputLanguageType');
      localStorage.setItem(`newline.${langType}`, e?.target.value);
      setNewLine({
        ...newLine,
        [outputLanguage]: e?.target.value,
      });
      // dispatch(
      //   setTranslationConfig({
      //     [e?.target.name]: e?.target.value,
      //   })
      // )
    },
    [newLine, outputLanguage]
  );

  return (
    <SettingComp
      showFirstTimeUpdateSnackbar={() => showFirstTimeUpdateSnackbar()}
      settingTitle={t('setting.title')}
      settingDescrip={t('setting.description')}
      separatorType={languageType(t)}
      separatorLabel={t('toolbox.separator.selectSeparator')}
      toolTipsTitle={
        outputLanguage === 'plain' ? t('input.seperateMessage') : ''
      }
      outputLanguage={outputLanguage}
      handleSetSeparatorStyle={(e: SelectChangeEvent<any>) =>
        handleSeparatorChange(e)
      }
      separateVal={separator[outputLanguage] || separateType}
      transLangMenu={transLangVal}
      transLangLabel={t('toolbox.transLang.select')}
      handelSetTransLang={(e: SelectChangeEvent<string>) =>
        dispatch(
          setTranslationConfig({
            [e?.target.name]: e?.target.value,
          })
        )
      }
      transLangVal={`${transConfig?.preferred_translation_language ?? 'de'}`}
      newLineLanguage={newLanguage(t)}
      newLineLabel={t('toolbox.newLine.newLineSelect')}
      newLineVal={`${newLine[outputLanguage]}`}
      handelSetNewLine={handleNewlineChange}
      containerClassName={styles['setting-container']}
      titleStyle={{
        color: theme.palette.primary.main,
      }}
      descrStyle={{
        color: theme.palette.primary.main,
      }}
    />
  );
};

export default SettingCompWrapper;
