import { Button, styled, useTheme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
  handleFileUpload: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

const UploadButton = ({ handleFileUpload }: Props) => {
  const theme = useTheme();
  const { t } = useTranslation('gallery');
  return (
    <Button
      component="label"
      role={undefined}
      variant="contained"
      tabIndex={-1}
      sx={{
        textTransform: 'capitalize',
        background: theme.palette.secondary.main,
        color: theme.palette.primary.main,
        '&:hover': {
          background: theme.palette.secondary.main,
          color: theme.palette.primary.main,
        },
      }}
      disableElevation
    >
      {t('uploadImg')}
      <VisuallyHiddenInput
        type="file"
        multiple
        accept="image/png,image/jpeg,image/jpg"
        onChange={handleFileUpload}
      />
    </Button>
  );
};

export default UploadButton;
