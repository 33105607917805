import {
  GET_ALL_UPLOADED_IMAGE,
  LOADING,
  SEARCH_UPLOADED_IMAGE,
  STOP_LOADING,
  UPLOAD_IMAGE,
  UPLOAD_LIST_IMAGE,
  RESET_IMAGE_UPLOAD_STATE,
  SET_UPLOAD_IMAGE_LIST,
  FEEDBACK_IMAGE,
  DELETE_IMAGE,
} from './types';

const initialState: any = {
  imageList: null,
  loading: false,
  uploadedImageList: {},
  tempImageList: {},
  heightlight: false,
};

export default function alertReducer(
  state = initialState,
  action: { type: any; payload: any }
) {
  const { type, payload } = action;
  switch (type) {
    case UPLOAD_IMAGE:
      return {
        ...state,
        imageList: null,
        uploadedImageList: {
          ...state.uploadedImageList,
          [payload.id]: payload,
        },
      };
    case UPLOAD_LIST_IMAGE:
      let updatedList = { ...state.uploadedImageList };
      if (updatedList[payload]) delete updatedList[payload];
      return {
        ...state,
        uploadedImageList: updatedList,
      };
    case GET_ALL_UPLOADED_IMAGE:
      return {
        ...state,
        imageList: payload,
      };
    case SEARCH_UPLOADED_IMAGE: {
      let imageList = {};
      let sourceList = {
        ownerList: {},
        otherList: {},
      };
      if (payload) {
        payload.list?.forEach((item: any) => {
          item.images?.forEach((image: any) => {
            imageList = {
              ...imageList,
              [image.uuid]: image,
            };
          });
          if (item.is_owner) {
            sourceList = {
              ...sourceList,
              ownerList: {
                ...sourceList.ownerList,
                [item.id]: { ...item, images: imageList },
              },
            };
          } else {
            sourceList = {
              ...sourceList,
              otherList: {
                ...sourceList.otherList,
                [item.id]: { ...item, images: imageList },
              },
            };
          }
          imageList = {};
        });
      }
      return {
        ...state,
        imageList: payload ? sourceList : payload,
        heightlight: payload.heightlight,
      };
    }
    case SET_UPLOAD_IMAGE_LIST:
      return {
        ...state,
        tempImageList: payload,
      };
    case FEEDBACK_IMAGE: {
      let listType = payload.isOwner ? 'ownerList' : 'otherList';
      let oldImageList = { ...state.imageList[listType] };
      oldImageList[payload.name] = {
        ...oldImageList[payload.name],
        images: {
          ...oldImageList[payload.name].images,
          [payload.uuid]: {
            ...oldImageList[payload.name].images[payload.uuid],
            feedback: payload.feedback,
          },
        },
      };
      return {
        ...state,
        imageList: { ...state.imageList, [listType]: { ...oldImageList } },
      };
    }
    case DELETE_IMAGE: {
      let listType = payload.isOwner ? 'ownerList' : 'otherList';
      let oldImageList = { ...state.imageList[listType] };
      let imageListItem = {
        ...oldImageList[payload.name].images,
      };

      delete imageListItem[payload.id];
      oldImageList[payload.name] = {
        ...oldImageList[payload.name],
        images: {
          ...imageListItem,
        },
      };
      return {
        ...state,
        imageList: { ...state.imageList, [listType]: { ...oldImageList } },
      };
    }
    case LOADING:
      return {
        ...state,
        loading: true,
      };
    case STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    case RESET_IMAGE_UPLOAD_STATE:
      return {
        ...state,
        ...payload,
      };
    default:
      return state;
  }
}
