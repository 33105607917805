import { createSelector } from 'reselect';
import type { User } from '@supabase/gotrue-js/src/lib/types';
export interface Supabase {
  user: User | null;
  session: any;
  error: any;
  loading: boolean;
}
interface RootState {
  supabase: Supabase;
}

// This used to memorize the selector value
export const supabaseSelector = createSelector(
  (state: RootState) => state.supabase,
  (supabase: Supabase) => {
    return {
      user: supabase.user,
      session: supabase.session,
      error: supabase.error,
      loading: supabase.loading,
    };
  }
);
