import { useTranslation } from 'react-i18next';
import GlossaryTable from './GlossaryTable';
import ShareGlossary from './shareGlossary/ShareGlossary';
import Grid from '@mui/material/Grid';

const GlossaryEntries = () => {
  const { t } = useTranslation(['glossary', 'common'], {
    nsMode: 'fallback',
  });
  // states

  return (
    <Grid
      item
      container
      xs={12}
    >
      <Grid
        item
        xs={12}
      >
        <h2 className="mt-4 mb-4">{t('glossaryEntries.myGlossary')}</h2>
      </Grid>
      <Grid
        item
        xs={12}
        mb="1.5em"
        mr="1em"
        display={'flex'}
        justifyContent={'flex-end'}
      >
        <ShareGlossary />
      </Grid>
      <Grid
        item
        xs={12}
      >
        <GlossaryTable />
      </Grid>
    </Grid>
  );
};

export default GlossaryEntries;
