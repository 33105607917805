import { useCallback, useEffect, useState } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Box from '@mui/material/Box';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import TranslationTypeFilter from './TranslationTypeFilter';
import RowToDisplay from './RowToDisplay';
import { getTransHistory } from 'src/redux/translationHistory/actions';
import ConfirmModel from './ConfirmModel';
import HistoryTable from './HistoryTable';
import { HistorySelector } from 'src/redux/translationHistory/selector';

const TranslationHistory = () => {
  const { t } = useTranslation(['easy_language', 'common'], {
    nsMode: 'fallback',
  });
  const theme = useTheme();
  const dispatch: any = useDispatch();

  const [entries, setEntries] = useState('10');
  const [showLang, setShowLang] = useState('');
  const [deleteJob, setDeleteJob] = useState({ id: '' });
  const [deleteAlert, setDeleteAlert] = useState(false);

  const filterValue = useSelector(
    (state: any) => HistorySelector(state).filters,
    shallowEqual
  );

  const fetchFilterUpdate = useCallback(() => {
    dispatch(
      getTransHistory({
        page: 1,
        page_size: Number(entries),
        ...filterValue,
      })
    );
  }, [dispatch, entries, filterValue]);
  useEffect(() => {
    let timeOut = setTimeout(() => {
      fetchFilterUpdate();
    }, 500);

    return () => clearTimeout(timeOut);
  }, [fetchFilterUpdate]);

  const onDeleteEntry = (row: any, alertTrigger: boolean) => {
    setDeleteJob(row);
    setDeleteAlert(alertTrigger);
  };

  return (
    <>
      <ConfirmModel
        id={deleteJob.id}
        deleteAlert={deleteAlert}
        setDeleteAlert={setDeleteAlert}
        onDeleteEntry={onDeleteEntry}
      />
      <style type="text/css">
        {`
          .feedback-cell.adjusted {
            background: ${theme.palette.general.teal} !important;
          },
      `}
      </style>

      <Container fluid>
        <h2
          className="mt-4 mb-4"
          style={{ textTransform: 'capitalize' }}
        >
          {t('history.myPastTranslations')}
        </h2>
        <div className="filterBar">
          <Box
            mb="1rem"
            display="flex"
            justifyContent={
              localStorage.getItem('i18nextLng') !== 'fr'
                ? 'space-between'
                : 'end'
            }
            alignItems="flex-end"
          >
            <TranslationTypeFilter
              showLang={showLang}
              setShowLang={setShowLang}
            />
            <RowToDisplay
              setEntries={setEntries}
              entries={entries}
            />
          </Box>
        </div>
        <Row>
          <Col md={{ span: 12 }}>
            <div
              style={{
                width: '100%',
                backgroundColor: theme.palette.general.white,
              }}
            >
              <HistoryTable
                onDeleteEntry={onDeleteEntry}
                showLang={showLang}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default TranslationHistory;
