import { Grid, useTheme } from '@mui/material';
import {
  SyntheticEvent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import ActionButton from './ActionButton';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { getSummary, resetSummary } from 'src/redux/summary/actions';
import { SummarySelector } from 'src/redux/summary/selector';
import LoadingHOC from 'src/coreUI/loadingHOC/LoadingHOC';
import { isEmpty } from 'lodash';
import SumaryText from './SumaryText';
import { HistorySelector } from 'src/redux/translationHistory/selector';
import { TranslateSelector } from 'src/redux/translation/selector';
import { ResizableBox, ResizeCallbackData } from 'react-resizable';
import './summary.css';
type Props = {
  inputTextValue: string;
};

const SummaryComp = ({ inputTextValue }: Props) => {
  const theme = useTheme();
  const dispatch: any = useDispatch();
  const [heightInput, setInputHeight] = useState<number>(300);

  const loading = useSelector(
    (state: any) => SummarySelector(state).loading,
    shallowEqual
  );

  const historyJob = useSelector(
    (state: any) => HistorySelector(state).historyJob,
    shallowEqual
  );

  const transjob = useSelector(
    (state: any) => TranslateSelector(state).transjob,
    shallowEqual
  );

  const summarySelector = useSelector(
    (state: any) => SummarySelector(state).summary,
    shallowEqual
  );

  const handleResizeUpdate = (e: SyntheticEvent, data: ResizeCallbackData) => {
    setInputHeight(data.node.offsetParent?.clientHeight ?? 0);
  };

  const getTheSummary = useCallback(() => {
    // const jobId = localStorage.getItem('jobId');
    if (!isEmpty(inputTextValue)) {
      let separatorType = localStorage.getItem('outputLanguageType');
      dispatch(
        getSummary({
          job_id: Number(
            transjob.jobId !== '-1' ? transjob.jobId : historyJob?.id
          ),
          input_text: inputTextValue,
          input_text_type: 'html',
          separator_style:
            localStorage.getItem(`separator.${separatorType}`) || 'none',
        })
      );
    } else {
      dispatch(resetSummary({ summary: null }));
    }
  }, [dispatch, historyJob?.id, inputTextValue, transjob.jobId]);

  useEffect(() => {
    if (historyJob || transjob.jobId !== '-1') {
      getTheSummary();
    }
  }, [getTheSummary, historyJob, transjob.jobId]);

  const summaryText = useMemo(
    () => (
      <Grid
        item
        xs={12}
      >
        <SumaryText heightInput={heightInput} />
      </Grid>
    ),
    [heightInput]
  );
  return (
    <Grid
      item
      container
      xs={12}
    >
      {/* <Grid
        item
        xs={12}
      >
        <Typography
          variant="subtitle2"
          gutterBottom
          sx={{
            textAlign: 'left',
            color: theme.palette.general.cyanBlue,
          }}
        >
          {t('title')}
        </Typography>
      </Grid> */}
      <LoadingHOC loading={loading || false}>
        {summarySelector ? (
          <ResizableBox
            height={337}
            minConstraints={[300, 337]}
            onResize={handleResizeUpdate}
            axis={'y'}
          >
            <Grid
              item
              container
              xs={12}
              sx={{
                border: `1px solid ${theme.palette.general.lightBlue}`,
                borderRadius: '5px',
              }}
            >
              {summaryText}
              <Grid
                item
                xs={12}
              >
                <ActionButton getTheSummary={getTheSummary} />
              </Grid>
            </Grid>
          </ResizableBox>
        ) : (
          <Grid
            item
            container
            xs={12}
            sx={{
              border: `1px solid ${theme.palette.general.lightBlue}`,
              borderRadius: '5px',
            }}
          >
            {summaryText}
          </Grid>
        )}
      </LoadingHOC>
    </Grid>
  );
};

export default SummaryComp;
