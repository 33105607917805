import { useState, useEffect, useRef } from 'react';
import { Button } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { useGridApiContext } from '@mui/x-data-grid';
import styles from './TranslationHistory.module.css';
import { Container, Grid } from '@mui/material';
import TextEditorComp from '../../coreUI/textEditorComp/TextEditorComp';
import { $getRoot, $insertNodes } from 'lexical';
import { $generateNodesFromDOM } from '@lexical/html';
import { useDispatch } from 'react-redux';
import { updateTransHistory } from 'src/redux/translationHistory/actions';

type Props = { value?: any; row: any; id?: any; field?: any; activeId?: any };
const EditTextAreaInputCell = (props: Props) => {
  const { t } = useTranslation(['common', 'easy_language'], {
    nsMode: 'fallback',
  });
  const dispatch: any = useDispatch();
  const { id, field, activeId, row } = props;

  let editorUpdateRef: any = useRef(null);

  const [userEditText, setUserEditText] = useState(props.value);
  const apiRef = useGridApiContext();

  useEffect(() => {
    if (editorUpdateRef && editorUpdateRef.current) {
      editorUpdateRef.current?.update(() => {
        // In the browser you can use the native DOMParser API to parse the HTML string.
        const parser = new DOMParser();
        const dom = parser.parseFromString(props.value, 'text/html');

        // Once you have the DOM instance it's easy to generate LexicalNodes.
        const nodes = $generateNodesFromDOM(editorUpdateRef.current, dom);

        // Select the root
        $getRoot().select();

        // Insert them at a selection.
        $insertNodes(nodes);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSaveButton = async () => {
    let feedback = row.feedback;
    const editTextArea = document.getElementById(`edit-textarea-${id}`);
    if (editTextArea?.innerText !== '') {
      feedback = 'adjusted';
    }

    let isHtmlTextInput = false;
    if (
      editTextArea?.innerHTML.includes('<strong') ||
      editTextArea?.innerHTML.includes('<a')
    ) {
      isHtmlTextInput = true;
    }

    setUserEditText(editTextArea?.innerHTML);
    dispatch(
      updateTransHistory({
        id: row.id,
        simpleTextUserEdited: isHtmlTextInput
          ? editTextArea?.innerHTML ?? ''
          : editTextArea?.innerText ?? '',
        userFeedback: feedback,
        category: row.category,
        tags: row.tags ? row.tags : '',
        input_text_type: isHtmlTextInput ? 'html' : 'plain_text',
      })
    );
    apiRef.current.stopCellEditMode({ id: id, field: field });
  };

  useEffect(() => {
    if (!localStorage.getItem(`switchInput-edit-textarea-${id}`)) {
      localStorage.setItem(`switchInput-edit-textarea-${id}`, 'on');
    }
  }, [id]);

  return (
    <Container
      style={{
        width: '100%',
        height: props.row.id === activeId ? '95%' : '46%',
        overflow: 'auto',
        display: 'grid',

        padding: '5px',
      }}
    >
      <Grid
        item
        xs={12}
        sx={{
          gridRowStart: 1,
          gridRowEnd: 11,
        }}
      >
        <TextEditorComp
          id={`edit-textarea-${id}`}
          disableInputField={false}
          inputText={userEditText}
          refEditor={editorUpdateRef}
          containerStyle={{ margin: 0, height: '100%' }}
          clearEditor={false}
          placeholder={t('feedback.edit.buttonText', { ns: 'easy_language' })}
          loader={false}
          noAnimationSlider={true}
          featureInclude={['bold']}
          showToolbar={true}
          showFormatIcon={false}
          toolbarStyle={{
            justifyContent: 'end',
          }}
          placeholderStyle={{
            top: '0',
            left: '3px',
          }}
          editorStyle={{
            padding: 0,
            height: '370px',
          }}
          editorScrollStyle={{
            height: 'auto',
          }}
          editorInnerStyle={{
            overflow: 'hidden',
          }}
          editable={true}
          axiosResize={'none'}
        />
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          gridRowStart: 11,
          gridRowEnd: 12,
        }}
      >
        <Button
          className={styles['save-button']}
          onClick={handleSaveButton}
        >
          {t('save')}
        </Button>
      </Grid>
    </Container>
  );
};

export default EditTextAreaInputCell;
