import { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import LogoutButton from '../../utils/LogoutButton';
import { sendEmailVerificationAction } from 'src/redux/supabase/actions';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { supabaseSelector } from 'src/redux/supabase/selector';
import { getUserPrefill, userPurchase } from 'src/redux/orderForm/actions';
import { UserPrefillSelector } from 'src/redux/orderForm/selector';
import { ErrorCheck, FormData, FormDataKeys } from 'src/types/orderForm.types';
import AccountDetail from './AccountDetail';
import RechnungAdresse from './RechnungAdresse';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Grid, Link, Typography, useTheme } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import { appMessageHandling } from 'src/utils/errorHandler';
import { isEmpty } from 'lodash';

type Props = { selectedProduct: { licencePeriod: string; id: number } };
const OrderForm = (props: Props) => {
  const dispatch: any = useDispatch();
  const theme = useTheme();
  const [formData, setFormData] = useState<FormData>({
    forename: '',
    surname: '',
    category: '',
    categoryFreeText: '',
    termsaccepted: false,
    dataprivacyaccepted: false,
    fullname: '',
    street: '',
    zipcode: '',
    city: '',
    vat: '',
    email: '',
  });

  const {
    forename,
    surname,
    category,
    categoryFreeText,
    termsaccepted,
    dataprivacyaccepted,
    fullname,
    street,
    zipcode,
    city,
    vat,
  } = formData;
  const handleChange = useCallback(
    (data: FormData | object) => {
      setFormData({ ...formData, ...data });
    },
    [formData]
  );

  const [showCategoryFreeText, setShowCategoryFreeText] = useState(false);

  const [error, setError] = useState<ErrorCheck | any>({
    termsaccepted: false,
    dataprivacyaccepted: false,
  });
  const supabaseSession: any = useSelector(
    (state: any) => supabaseSelector(state).session,
    shallowEqual
  );

  const history = useNavigate();
  const supabaseUser: any = useSelector(
    (state: any) => supabaseSelector(state).user,
    shallowEqual
  );

  const userPrefill: any = useSelector(
    (state: any) => UserPrefillSelector(state).userPrefil,
    shallowEqual
  );

  const purchase: any = useSelector(
    (state: any) => UserPrefillSelector(state).purchase,
    shallowEqual
  );

  const loading: any = useSelector(
    (state: any) => UserPrefillSelector(state).loading,
    shallowEqual
  );

  const licencePeriodDict: { [key: string]: string } = {
    Monatlich: 'monthly',
    Jährlich: 'yearly',
  };
  useEffect(() => {
    if (supabaseSession?.access_token && !userPrefill) {
      dispatch(getUserPrefill());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const getDetails = useCallback(() => {
    let categoryText = userPrefill.user.category;
    if (['translator', 'officeManager'].includes(categoryText)) {
      setShowCategoryFreeText(false);
    } else {
      setShowCategoryFreeText(true);
    }

    handleChange({
      forename: userPrefill.user.forename,
      surname: userPrefill.user.surname,
      street: userPrefill.company.street,
      zipcode: userPrefill.company.zipcode,
      city: userPrefill.company.city,
      fullname: userPrefill.company.fullname,
      vat: userPrefill.company?.vat,
      ...(supabaseUser.email ? { email: supabaseUser.email } : {}),
      ...(['translator', 'officeManager'].includes(categoryText)
        ? { category: userPrefill.user.category }
        : {
            category: 'other',
            categoryFreeText: userPrefill.user.category,
          }),
    });
  }, [handleChange, supabaseUser.email, userPrefill]);

  useEffect(() => {
    if (userPrefill) {
      getDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userPrefill]);

  const sendPurchaseRequest = async () => {
    const categoryText = ['translator', 'officeManager'].includes(
      category || ''
    )
      ? category
      : categoryFreeText;

    const newUserData: any = {
      user: {
        forename: forename,
        surname: surname,
        category: categoryText,
        termsaccepted: termsaccepted,
        dataprivacyaccepted: dataprivacyaccepted,
        product: props.selectedProduct.id,
        licenceperiod: licencePeriodDict[props.selectedProduct.licencePeriod],
      },
      company: {
        fullname: fullname,
        street: street,
        zipcode: zipcode,
        city: city,
        vat: vat,
      },
    };

    dispatch(userPurchase(newUserData));
  };

  useEffect(() => {
    if (purchase) {
      history('/thankyou');
    }
  }, [history, purchase]);

  // Check error on typing
  const handleEmptyFieldError = (field: FormDataKeys) => {
    // Check empty field
    let errorVal: { [key: string]: boolean } = {
      ...error,
      [field]: isEmpty(formData[field]),
    };

    if (category !== 'other') delete errorVal['categoryFreeText'];
    setError({
      ...errorVal,
    });
  };

  async function handleSubmit(e: any) {
    e.preventDefault();

    // check error on submit
    let errorVal: { [key: string]: boolean | string } = {
      ...error,
    };
    for (let item in formData) {
      let itemVal = item as FormDataKeys;
      errorVal = {
        ...errorVal,
        [itemVal]: ['termsaccepted', 'dataprivacyaccepted'].includes(item)
          ? !formData[itemVal]
          : isEmpty(formData[itemVal]),
      };
    }
    if (category !== 'other') delete errorVal['categoryFreeText'];
    setError({
      ...errorVal,
    });

    // Make req
    if (Object.values(errorVal).filter((item) => item === true).length === 0) {
      if (supabaseUser) {
        // user is already logged in, so send purchase request
        await sendPurchaseRequest();
        if (!supabaseUser.emailVerified) {
          await dispatch(sendEmailVerificationAction());
        }
      } else {
        appMessageHandling(
          dispatch,
          'Sie müssen sich erst einloggen oder registrieren.',
          'error'
        );
      }
    }
  }

  const handleRoleChange = (e: any) => {
    if (e?.target.value === 'other') {
      setShowCategoryFreeText(true);
    } else {
      setShowCategoryFreeText(false);
    }
  };

  return (
    <>
      <Grid
        item
        container
        xs={12}
        spacing={4}
      >
        <Grid
          item
          xs={12}
          sx={{ mt: '2em' }}
        >
          <AccountDetail
            formData={formData}
            handleChange={handleChange}
            showCategoryFreeText={showCategoryFreeText}
            handleRoleChange={handleRoleChange}
            error={error}
            handleEmptyFieldError={handleEmptyFieldError}
          />
        </Grid>
        <Grid
          item
          xs={12}
        >
          <RechnungAdresse
            formData={formData}
            handleChange={handleChange}
            error={error}
            handleEmptyFieldError={handleEmptyFieldError}
          />
        </Grid>

        <Grid
          item
          xs={12}
        >
          <FormControlLabel
            control={
              <Checkbox
                sx={{
                  color:
                    error.termsaccepted || error.dataprivacyaccepted
                      ? theme.palette.error.main
                      : 'inherit',
                }}
              />
            }
            onChange={(e: React.SyntheticEvent | any) => {
              handleChange({
                termsaccepted: e?.target.checked,
                dataprivacyaccepted: e?.target.checked,
              });
              setError({
                ...error,
                termsaccepted: !e?.target.checked,
                dataprivacyaccepted: !e?.target.checked,
              });
            }}
            value={termsaccepted || dataprivacyaccepted}
            label={
              <Typography
                variant="body2"
                sx={{ display: 'inline-block' }}
              >
                Ich habe die{' '}
                <Link
                  href="/dataprivacy"
                  target="_blank"
                >
                  Datenschutzerklärung
                </Link>{' '}
                und{' '}
                <Link
                  href="/licence"
                  target="_blank"
                >
                  AGB
                </Link>{' '}
                gelesen und akzeptiert *
              </Typography>
            }
            sx={{
              width: '100%',
              textAlign: 'left',
            }}
          />
        </Grid>
        <Grid
          item
          xs={12}
        >
          <LoadingButton
            loading={loading}
            fullWidth
            variant="contained"
            onClick={handleSubmit}
            sx={{
              textTransform: 'capitalize',
              p: '8px 3em',
              borderRadius: '5px',
            }}
            id="otp-submit"
          >
            Zahlungspflichtig bestellen
          </LoadingButton>
        </Grid>
        <Grid
          item
          xs={12}
        >
          <Typography
            variant="body2"
            sx={{ textAlign: 'left' }}
          >
            Im Anschluss an Ihre Bestellung überprüfen wir diese und melden uns
            innerhalb der kommenden zwei Werktage bei Ihnen bezüglich der
            Aktivierung Ihres Account. Dann beginnt Ihre kostenlose, 14-tägige
            Testphase.
          </Typography>
          <Typography
            variant="body2"
            sx={{ textAlign: 'left', mt: '1em' }}
          >
            * Pflichfeld
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          sx={{ mb: '2em' }}
        >
          <LogoutButton />
        </Grid>
      </Grid>
    </>
  );
};

export default OrderForm;
