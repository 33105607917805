import React, { useRef, FC } from 'react';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import styles from './HelpSection.module.css';
import { useTranslation } from 'react-i18next';

const HelpSection: FC = () => {
  const ref = useRef(null);

  const handleClick = (event: React.MouseEvent<HTMLParagraphElement>) => {
    // track event
  };
  const { t } = useTranslation('common');

  return (
    <div>
      <div ref={ref}>
        <OverlayTrigger
          trigger="click"
          placement="top"
          container={ref.current}
          containerPadding={20}
          overlay={
            <Popover id="popover-contained">
              <Popover.Title as="h3">{t('help.contact.title')}</Popover.Title>
              <Popover.Content>
                <strong>Email:</strong>{' '}
                <a href="mailto:team@summ-ai.com">team@summ-ai.com</a>
                <br />
                <strong>{t('help.contact.phone')}:</strong> +49 178 3674624
              </Popover.Content>
            </Popover>
          }
        >
          <p
            className={styles['help-link']}
            onClick={handleClick}
          >
            {t('help.help')}
          </p>
        </OverlayTrigger>
      </div>
      <p>
        {t('feedback')}
        <br />
        <a href="mailto:feedback@summ-ai.com?subject=Feedback an SUMM&body=Hallo liebes SUMM-Team,%0D%0A%0D%0Aich habe folgendes Feedback:%0D%0A%0D%0A">
          feedback@summ-ai.com{' '}
        </a>
      </p>
    </div>
  );
};

export default HelpSection;
