import { useTheme } from '@mui/material';
import { Form } from 'react-bootstrap';

type Props = {
  params: any;
  activeId: string | null;
  value: string;
  handleClick?: () => void;
  id?: string;
};

const TextDisplay = ({
  params,
  activeId,
  value,
  handleClick = undefined,
  id = 'output-history',
}: Props) => {
  const theme = useTheme();
  return (
    <Form.Group
      style={{
        width: '100%',
        height: params.row.id === activeId ? '95%' : '15em',
        background: theme.palette.general.paleBlue,
        border: `1px solid ${theme.palette.general.lightSteelBlue}`,
        overflow: 'auto',
        textAlign: 'left',
        padding: '20px',
        marginBottom: 0,
      }}
      onClick={handleClick}
    >
      <div
        id={id}
        dangerouslySetInnerHTML={{
          __html: value.replace(/\n/g, '<br/>'),
        }}
        style={{
          textWrap: 'wrap',
        }}
      />
    </Form.Group>
  );
};

export default TextDisplay;
