import { useTranslation } from 'react-i18next';
import { useSelector, shallowEqual } from 'react-redux';
import TextareaComp from 'src/coreUI/TextAreaComp/TextareaComp';
import { SummarySelector } from 'src/redux/summary/selector';

type Props = {
  heightInput: number;
};

const SumaryText = ({ heightInput }: Props) => {
  const { t } = useTranslation('summary');
  const summarySelector = useSelector(
    (state: any) => SummarySelector(state).summary,
    shallowEqual
  );
  return (
    <TextareaComp
      value={summarySelector?.translated_summary || t('noSummary')}
      disabled={true}
      multiline={true}
      // maxRows={7}
      // minRows={7}
      style={{
        height: heightInput - 37,
        minHeight: 337 - 37,
        width: '100%',
        '& .base-Input-multiline': {
          width: '100%',
          border: `none !important`,
          resize: 'none !important',
          boxShadow: 'none !important',
          '&:focus': {
            outline: 'none !important',
            boxShadow: 'none !important',
          },
        },
      }}
    />
  );
};

export default SumaryText;
