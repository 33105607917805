import style from './TextStyle.module.css';
import {
  $getSelection,
  $isRangeSelection,
  $createParagraphNode,
} from 'lexical';
import { $setBlocksType } from '@lexical/selection';
import { $createCodeNode } from '@lexical/code';
import { $createHeadingNode } from '@lexical/rich-text';
import {
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  REMOVE_LIST_COMMAND,
} from '@lexical/list';
import { MenuOptions } from '../dropdownMenuComp/DropdownMenuComp';

export const supportedBlockTypes: { [key: string]: string } = {
  paragraph: 'paragraph',
  bullet: 'ul',
  number: 'ol',
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  // 'code',
};

export const reflectValue: { [key: string]: string } = {
  paragraph: 'paragraph',
  ul: 'ul',
  ol: 'ol',
  h1: 'h1',
  h2: 'h2',
  h3: 'h3',
  h4: 'h4',
  h5: 'h5',
  h6: 'h6',
  // code: 'code',
};

export const blockTypeToBlockName: (
  t: any,
  editor?: any,
  blockType?: any
) => { [key: string]: MenuOptions } = (t, editor, blockType) => {
  return {
    paragraph: {
      value: 'paragraph',
      blockName: 'paragraph',
      text: t('Standard'),
      id: 'op-9',
      icon: <span className={style['icon'] + ' ' + style['paragraph']} />,
      handleClickMethod: () =>
        editor.update(() => {
          const selection = $getSelection();

          if ($isRangeSelection(selection)) {
            $setBlocksType(selection, () => $createParagraphNode());
          }
        }),
    },
    ol: {
      value: 'ol',
      blockName: 'number',
      text: t('Nummerierung'),
      id: 'op-8',
      icon: <span className={style['icon'] + ' ' + style['numbered-list']} />,
      handleClickMethod:
        blockType !== 'ol'
          ? () => editor.dispatchCommand(INSERT_ORDERED_LIST_COMMAND)
          : () => editor.dispatchCommand(REMOVE_LIST_COMMAND),
    },
    ul: {
      value: 'ul',
      blockName: 'bullet',
      text: t('Aufzählungszeichen'),
      id: 'op-10',
      icon: <span className={style['icon'] + ' ' + style['bullet-list']} />,
      handleClickMethod:
        blockType !== 'ul'
          ? () => editor.dispatchCommand(INSERT_UNORDERED_LIST_COMMAND)
          : () => editor.dispatchCommand(REMOVE_LIST_COMMAND),
    },
    h1: {
      value: 'h1',
      blockName: 'h1',
      text: t('Überschrift_1'),
      id: 'op-2',
      icon: <span className={style['icon'] + ' ' + style['large-heading']} />,
      handleClickMethod: () =>
        editor.update(() => {
          const selection = $getSelection();

          if ($isRangeSelection(selection)) {
            $setBlocksType(selection, () => $createHeadingNode('h1'));
          }
        }),
    },
    h2: {
      value: 'h2',
      blockName: 'h2',
      text: t('Überschrift_2'),
      id: 'op-3',
      icon: <span className={style['icon'] + ' ' + style['small-heading']} />,
      handleClickMethod: () =>
        editor.update(() => {
          const selection = $getSelection();

          if ($isRangeSelection(selection)) {
            $setBlocksType(selection, () => $createHeadingNode('h2'));
          }
        }),
    },
    h3: {
      value: 'h3',
      blockName: 'h3',
      text: t('Überschrift_3'),
      id: 'op-4',
      icon: <span className={style['icon'] + ' ' + style['small-heading']} />,
      handleClickMethod: () =>
        editor.update(() => {
          const selection = $getSelection();

          if ($isRangeSelection(selection)) {
            $setBlocksType(selection, () => $createHeadingNode('h3'));
          }
        }),
    },
    h4: {
      value: 'h4',
      blockName: 'h4',
      text: t('Überschrift_4'),
      id: 'op-5',
      icon: <span className={style['icon'] + ' ' + style['small-heading']} />,
      handleClickMethod: () =>
        editor.update(() => {
          const selection = $getSelection();

          if ($isRangeSelection(selection)) {
            $setBlocksType(selection, () => $createHeadingNode('h4'));
          }
        }),
    },
    h5: {
      value: 'h5',
      blockName: 'h5',
      text: t('Überschrift_5'),
      id: 'op-6',
      icon: <span className={style['icon'] + ' ' + style['small-heading']} />,
      handleClickMethod: () =>
        editor.update(() => {
          const selection = $getSelection();

          if ($isRangeSelection(selection)) {
            $setBlocksType(selection, () => $createHeadingNode('h5'));
          }
        }),
    },
    h6: {
      value: 'h6',
      blockName: 'h6',
      text: t('Überschrift_6'),
      id: 'op-7',
      icon: <span className={style['icon'] + ' ' + style['small-heading']} />,
      handleClickMethod: () =>
        editor.update(() => {
          const selection = $getSelection();

          if ($isRangeSelection(selection)) {
            $setBlocksType(selection, () => $createHeadingNode('h6'));
          }
        }),
    },

    // code: {
    //   value: 'code',
    //   text: 'Code Block',
    //   id: 'op-1',
    //   icon: <span className={'iconcode'} />,
    //   handleClickMethod: () =>
    //     editor.update(() => {
    //       const selection = $getSelection();

    //       if ($isRangeSelection(selection)) {
    //         $setBlocksType(selection, () => $createCodeNode());
    //       }
    //     }),
    // },
  };
};

export const FONT_FAMILY_OPTIONS: MenuOptions[] = [
  { value: 'Arial', text: 'Arial', id: 'ff-1' },
  { value: 'Courier New', text: 'Courier New', id: 'ff-2' },
  { value: 'Georgia', text: 'Georgia', id: 'ff-3' },
  { value: 'Times New Roman', text: 'Times New Roman', id: 'ff-4' },
  { value: 'Trebuchet MS', text: 'Trebuchet MS', id: 'ff-5' },
  { value: 'Verdana', text: 'Verdana', id: 'ff-6' },
];

export const FONT_SIZE_OPTIONS: MenuOptions[] = [
  { value: '10px', text: '10px', id: 'fs-1' },
  { value: '11px', text: '11px', id: 'fs-2' },
  { value: '12px', text: '12px', id: 'fs-3' },
  { value: '13px', text: '13px', id: 'fs-4' },
  { value: '14px', text: '14px', id: 'fs-5' },
  { value: '15px', text: '15px', id: 'fs-6' },
  { value: '16px', text: '16px', id: 'fs-7' },
  { value: '17px', text: '17px', id: 'fs-8' },
  { value: '18px', text: '18px', id: 'fs-9' },
  { value: '19px', text: '19px', id: 'fs-10' },
  { value: '20px', text: '20px', id: 'fs-11' },
];

export type toolbarItemTypes = Array<
  | 'fontFamily'
  | 'fontSize'
  | 'redo'
  | 'undo'
  | 'bold'
  | 'italic'
  | 'underline'
  | 'leftAlign'
  | 'justifyAlign'
  | 'centerAlign'
  | 'insertLink'
  | 'copy'
  | 'blockOption'
  | 'All'
>;
