import { Grid, IconButton, Tooltip, useTheme } from '@mui/material';
import ThumbUpOffAltIcon from '@mui/icons-material/ThumbUpOffAlt';
import ThumbDownOffAltIcon from '@mui/icons-material/ThumbDownOffAlt';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import CopyButtomComp from 'src/coreUI/copyButtonComp/CopyButtomComp';
import { useTranslation } from 'react-i18next';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import ThumbUpIcon from '@mui/icons-material/ThumbUp';
import ThumbDownIcon from '@mui/icons-material/ThumbDown';
import { updateSummary } from 'src/redux/summary/actions';
import { SummarySelector } from 'src/redux/summary/selector';

type Props = {
  getTheSummary: () => void;
};

const ActionButton = ({ getTheSummary }: Props) => {
  const { t } = useTranslation('summary');
  const dispatch: any = useDispatch();
  const theme = useTheme();

  const summarySelector = useSelector(
    (state: any) => SummarySelector(state).summary,
    shallowEqual
  );

  const handleClick = (value: number) => {
    if (summarySelector) {
      dispatch(updateSummary({ id: summarySelector.id, feedback: value }));
    }
  };

  return (
    summarySelector && (
      <Grid
        item
        container
        xs={12}
        display={'flex'}
        justifyContent={'end'}
      >
        <Grid
          item
          xs="auto"
        >
          <Tooltip
            title={t('regenerate')}
            placement="top"
          >
            <IconButton
              aria-label="regenerate"
              onClick={getTheSummary}
            >
              <AutorenewIcon
                fontSize="small"
                sx={{ color: theme.palette.primary.main }}
              />
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid
          item
          xs="auto"
        >
          <Tooltip
            title={t('like')}
            placement="top"
          >
            <IconButton
              aria-label="like_feedback"
              onClick={() => handleClick(1)}
            >
              {summarySelector && summarySelector.feedback === 1 ? (
                <ThumbUpIcon
                  fontSize="small"
                  sx={{ color: theme.palette.primary.main }}
                />
              ) : (
                <ThumbUpOffAltIcon
                  fontSize="small"
                  sx={{ color: theme.palette.primary.main }}
                />
              )}
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid
          item
          xs="auto"
        >
          <Tooltip
            title={t('dislike')}
            placement="top"
          >
            <IconButton
              aria-label="dislike_feedback"
              onClick={() => handleClick(0)}
            >
              {summarySelector && summarySelector.feedback === 0 ? (
                <ThumbDownIcon
                  fontSize="small"
                  sx={{ color: theme.palette.primary.main }}
                />
              ) : (
                <ThumbDownOffAltIcon
                  fontSize="small"
                  sx={{ color: theme.palette.primary.main }}
                />
              )}
            </IconButton>
          </Tooltip>
        </Grid>
        <Grid
          item
          xs={2}
        >
          <CopyButtomComp
            value={summarySelector && summarySelector.translated_summary}
            buttonStyle={{ position: 'relative' }}
            size="small"
          />
        </Grid>
      </Grid>
    )
  );
};

export default ActionButton;
