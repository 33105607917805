import { createSelector } from 'reselect';

export interface GlossaryReducers {
  loading: boolean;
  glossaryItems: null | { difficultWords: { [key: string]: any } };
  unAuthAlert: boolean;
  AIWordExplanation: null | [];
  glossaryTags: null | [];
  highLightWord: { [key: string]: string };
  selectedTagsList: string[] | null;
  difficultWords: any | null;
  difficultWordsToShow: any | null;
  otherWordExplanation: any | null;
  hasCopyRestriction: any | null;
}

interface RootState {
  glossary: GlossaryReducers;
}

// This used to memorize the selector value
export const glossarySelector = createSelector(
  (state: RootState) => state.glossary,
  (glossary: GlossaryReducers) => {
    return {
      loading: glossary.loading,
      glossaryItems: glossary.glossaryItems,
      unAuthAlert: glossary.unAuthAlert,
      AIWordExplanation: glossary.AIWordExplanation,
      glossaryTags: glossary.glossaryTags,
      highLightWord: glossary.highLightWord,
      selectedTagsList: glossary.selectedTagsList,
      difficultWords: glossary.difficultWords,
      difficultWordsToShow: glossary.difficultWordsToShow,
      otherWordExplanation: glossary.otherWordExplanation,
      hasCopyRestriction: glossary.hasCopyRestriction,
    };
  }
);
