import { useState, useCallback, useMemo, forwardRef } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@mui/styles';
import Collapse from '@mui/material/Collapse';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import FileUploading from '../ImageUploadComp/FileUploading';
import { useSelector, shallowEqual } from 'react-redux';
import { UploadImageSelector } from 'src/redux/imageUpload/selector';
import { useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { CustomContentProps, SnackbarContent, useSnackbar } from 'notistack';

declare module 'notistack' {
  interface VariantOverrides {
    reportComplete: true;
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    '@media (min-width:600px)': {
      minWidth: '344px !important',
    },
  },
  card: {
    width: '100%',
  },
  actionRoot: {
    padding: '8px 8px 8px 16px',
    justifyContent: 'space-between',
  },
  icons: {
    marginLeft: 'auto',
  },
  expand: {
    padding: '8px 8px',
    transform: 'rotate(0deg)',
    color: '#000',
    transition: 'all .2s',
  },
  expandOpen: {
    transform: 'rotate(180deg)',
  },
  paper: {
    backgroundColor: '#fff',
    padding: 16,
    maxHeight: 300,
    overflowY: 'auto',
  },
  checkIcon: {
    fontSize: 20,
    paddingRight: 4,
  },
  button: {
    padding: 0,
    textTransform: 'none',
  },
}));

interface ReportCompleteProps extends CustomContentProps {
  allowDownload?: boolean;
}
const ReportComplete = forwardRef<HTMLDivElement, ReportCompleteProps>(
  ({ id, ...props }, ref) => {
    const classes = useStyles();
    const { closeSnackbar } = useSnackbar();
    const theme = useTheme();
    const { t } = useTranslation('gallery');
    const [expanded, setExpanded] = useState(false);
    const handleExpandClick = useCallback(() => {
      setExpanded((oldExpanded) => !oldExpanded);
    }, []);

    const tempImageList = useSelector(
      (state: any) => UploadImageSelector(state).tempImageList,
      shallowEqual
    );

    const uploadedImageList = useSelector(
      (state: any) => UploadImageSelector(state).uploadedImageList,
      shallowEqual
    );

    const files = useMemo(
      () =>
        Object.values(tempImageList).map((file: any, index: number) => {
          return (
            <FileUploading
              key={file.path + index}
              file={file}
            />
          );
        }),
      [tempImageList]
    );

    const handleDismiss = useCallback(() => {
      closeSnackbar(id);
    }, [closeSnackbar, id]);

    return (
      <SnackbarContent
        ref={ref}
        className={classes.root}
      >
        <Card
          className={classes.card}
          style={{ backgroundColor: theme.palette.primary.main }}
        >
          <CardActions classes={{ root: classes.actionRoot }}>
            <Typography
              variant="body2"
              sx={{
                color: theme.palette.secondary.main,
              }}
            >
              {t('uploadedImage')} ({Object.values(uploadedImageList).length}/
              {Object.values(tempImageList).length})
            </Typography>
            <div className={classes.icons}>
              <IconButton
                aria-label="Show more"
                size="small"
                className={clsx(classes.expand, {
                  [classes.expandOpen]: expanded,
                })}
                onClick={handleExpandClick}
              >
                <ExpandMoreIcon sx={{ color: theme.palette.secondary.main }} />
              </IconButton>
              <IconButton
                size="small"
                className={classes.expand}
                onClick={handleDismiss}
              >
                <CloseIcon
                  fontSize="small"
                  sx={{ color: theme.palette.secondary.main }}
                />
              </IconButton>
            </div>
          </CardActions>
          <Collapse
            in={expanded}
            timeout="auto"
            unmountOnExit
          >
            <Paper className={classes.paper}>{files}</Paper>
          </Collapse>
        </Card>
      </SnackbarContent>
    );
  }
);

ReportComplete.displayName = 'ReportComplete';

export default ReportComplete;
