import SignUpField from './SignUpField';
import CompanyField from './CompanyField';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'react-bootstrap';
import Typography from '@mui/material/Typography';
import { Alert, Link } from '@mui/material';

type Props = {};
const SignupForm = (props: Props) => {
  const { t } = useTranslation('common');
  return (
    <Container>
      <Row>
        <Col
          style={{
            justifyContent: 'center',
            display: 'grid',
          }}
        >
          <h3 className="pt-2 text-center mb-4">
            {localStorage.getItem('signup_form')
              ? t('signupForm.registrationDetails')
              : t('signupForm.registration')}
          </h3>
          <Alert
            severity="info"
            icon={false}
            sx={{ justifyContent: 'center', width: 'fit-content' }}
          >
            <Typography
              variant="subtitle1"
              gutterBottom
              style={{
                whiteSpace: 'pre-line',
              }}
            >
              {t('infoContactSales')}
              <Link
                href="https://summ-ai.com/gespraech-vereinbaren/"
                target="_blank"
                rel="noopener"
              >
                Jetzt Kontakt aufnehmen
              </Link>
            </Typography>
          </Alert>
          {localStorage.getItem('signup_form') ? (
            <CompanyField />
          ) : (
            <SignUpField />
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default SignupForm;
