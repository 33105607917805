import { Button, Modal } from 'react-bootstrap';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  deleteTransHistory,
  getTransHistory,
} from 'src/redux/translationHistory/actions';
import { HistorySelector } from 'src/redux/translationHistory/selector';

type Props = {
  id: string;
  deleteAlert: boolean;
  onDeleteEntry: (row: any, alertTrigger: boolean) => void;
  setDeleteAlert: any;
};

const ConfirmModel = ({
  id,
  deleteAlert,
  onDeleteEntry,
  setDeleteAlert,
}: Props) => {
  const dispatch: any = useDispatch();

  const currentpage = useSelector(
    (state: any) => HistorySelector(state).currentPage,
    shallowEqual
  );

  const entries = useSelector(
    (state: any) => HistorySelector(state).entries,
    shallowEqual
  );

  const filterValue = useSelector(
    (state: any) => HistorySelector(state).filters
  );

  const deleteEntry = async () => {
    await dispatch(deleteTransHistory(id));
    dispatch(
      getTransHistory(
        {
          page: currentpage,
          page_size: entries,
          ...(filterValue ? filterValue : {}),
        },
        false
      )
    );
    onDeleteEntry(id, false);
  };

  return (
    <Modal
      show={deleteAlert}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => {
        setDeleteAlert(false);
      }}
      backdrop="static"
    >
      <Modal.Body>
        <span>Sind Sie sich sicher, dass Sie den Eintrag löschen möchten?</span>
      </Modal.Body>
      <Modal.Footer>
        <Button
          variant="danger"
          onClick={(e) => deleteEntry()}
        >
          Ja, löschen
        </Button>
        <Button
          variant="primary"
          onClick={(e) => {
            onDeleteEntry('', false);
          }}
        >
          Abbrechen
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmModel;
