import {
  LOADING,
  STOP_LOADING,
  GET_HISTORY,
  UPDATE_HISTORY,
  TAGS_UPDATE,
  DELETE_HISTORY,
  SET_FILTER_VALUE,
  UPDATE_PAINATION,
  UPDATE_JOB_VALUE,
} from './types';

const initialState: any = {
  jobsList: {},
  loading: false,
  tags: {},
  count: 0,
  next: null,
  previous: null,
  currentPage: 1,
  entries: '10',
  filters: null,
  historyJob: null,
};

export default function alertReducer(
  state = initialState,
  action: { type: any; payload: any }
) {
  const { type, payload } = action;
  switch (type) {
    case GET_HISTORY: {
      const currentTags: { [key in string]: string[] } = {};
      const jobListUpdate: { [key in string]: object } = {};
      payload.results.map((row: any) => {
        if (row.category !== null && row.category.split(':').length > 1) {
          const splitArray = row.category.split(':');
          row.customCategory = splitArray[splitArray.length - 1];
          row.category = 'others';
        } else {
          row.customCategory = '';
        }
        row.timestampcreated = new Date(row.timestampcreated);
        jobListUpdate[row.id] = row;
        currentTags[row.id] = row.tags ? row.tags.split(',') : [];
        return row;
      });
      return {
        ...state,
        jobsList: jobListUpdate,
        tags: currentTags,
        count: payload.count,
        next: payload.next,
        previous: payload.previous,
        currentPage: payload.currentPage,
        entries: payload.entries,
      };
    }
    case UPDATE_HISTORY: {
      let updatedJobsList = { ...state.jobsList };
      if (payload.category.startsWith('others:')) {
        let splitVAlue = payload.category.split('others:');
        payload.category = 'others';
        payload.customCategory = splitVAlue[1];
      }
      updatedJobsList[payload.jobid] = {
        ...updatedJobsList[payload.jobid],
        ...payload,
      };
      return {
        ...state,
        jobsList: updatedJobsList,
      };
    }
    case DELETE_HISTORY: {
      let oldJobsList = { ...state.jobsList };
      delete oldJobsList[payload];
      return {
        ...state,
        jobsList: oldJobsList,
        count: state.count - 1,
      };
    }
    case TAGS_UPDATE: {
      return {
        ...state,
        tags: payload,
      };
    }
    case SET_FILTER_VALUE:
      return {
        ...state,
        filters: payload,
      };
    case LOADING:
      return {
        ...state,
        loading: true,
      };
    case STOP_LOADING:
      return {
        ...state,
        loading: false,
      };
    case UPDATE_PAINATION:
      return {
        ...state,
        currentPage: payload,
      };
    case UPDATE_JOB_VALUE:
      return {
        ...state,
        historyJob: payload
          ? {
              ...payload,
              originaltext: payload?.originaltext.replaceAll('\n', '<br/>'),
              simpletext: payload?.simpletext.replaceAll('\n', '<br/>'),
            }
          : null,
      };
    default:
      return state;
  }
}
