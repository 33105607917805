import React from 'react';
import { Dropdown } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const LanguageSwitchBtn = () => {
  const { t, i18n } = useTranslation('common');

  const changeLanguage = (language) => {
    if (language === 'de') {
      i18n.changeLanguage(language);
      localStorage.setItem('i18nextLng', 'de');
    } else if (language === 'en') {
      i18n.changeLanguage(language);
      localStorage.setItem('i18nextLng', 'en');
    } else if (language === 'fr') {
      i18n.changeLanguage(language);
      localStorage.setItem('i18nextLng', 'fr');
    }
  };

  return (
    <Dropdown className="ml-auto">
      <Dropdown.Toggle
        variant=""
        id="choose-language-dropdown"
      >
        {t('nav.language')}
      </Dropdown.Toggle>

      <Dropdown.Menu>
        <Dropdown.Item
          onClick={() => {
            changeLanguage('de');
          }}
        >
          DE
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => {
            changeLanguage('en');
          }}
        >
          EN
        </Dropdown.Item>
        <Dropdown.Item
          onClick={() => {
            changeLanguage('fr');
          }}
        >
          FR
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default LanguageSwitchBtn;
