import React from 'react';
import { QuestionCircle } from 'react-bootstrap-icons';

const HelpButton = () => {
  return (
    <a
      href="https://summai.notion.site/FAQ-Hilfe-741eb93518944ce0b543e2d1b5aad0c2"
      target="_blank"
      rel="noreferrer"
    >
      <QuestionCircle size={60} />
    </a>
  );
};

export default HelpButton;
