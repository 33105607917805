import { appMessageHandling } from '../../utils/errorHandler';
import {
  AI_WORD_EXPLANATION,
  CREATE_GLOSSARY,
  DELETE_GLOSSARY,
  DIFFICULT_WORDS,
  DIFFICULT_WORDS_TO_SHOW,
  EMPTY_STATE_VALUE,
  GET_GLOSSARY_TAGS,
  LOADING,
  OWN_EXPLANATIONS,
  SEARCH_GLOSSARY,
  SELECTED_TAGS_LIST,
  SHOW_AI_SEARCH_UNAUTH_ALERT,
  SHOW_GLOSSARY_COPY_RESTRICTION_ALERT,
  STOP_LOADING,
} from './types';
import Sentry from '../../config/sentryConfig';
import glossaryService from 'src/services/glossary.service';
import {
  GlossaryData,
  OptionalGlossaryReducers,
} from 'src/types/glossaryTypes.types';
import { t } from 'i18next';
import { DifficultWords } from '@summ-ai-github/summ-frontend-package/dist/components/glossaryFieldComp';

export const searchGlossery =
  (data: { inputText: string; email: string }) =>
  async (dispatch: any, state: any) => {
    try {
      dispatch(loading());
      dispatch(
        emptyStateValueGlossary({
          glossaryItems: { difficultWords: [] },
          selectedTagsList: null,
          difficultWords: [],
          difficultWordsToShow: undefined,
          otherWordExplanation: undefined,
          hasCopyRestriction: undefined,
        })
      );
      const resDtails: any = await glossaryService.searchGlossaryApi(data);
      if (!resDtails.data.difficultWords) {
        return appMessageHandling(dispatch, 'error.serverDown', 'error');
      }

      if (__hasCopyRestriction(resDtails.data.difficultWords)) {
        appMessageHandling(
          dispatch,
          t('warning', { ns: 'glossary' }),
          'warning'
        );
      }

      await dispatch({
        type: SEARCH_GLOSSARY,
        payload: resDtails?.data,
      });
    } catch (e: any) {
      Sentry.captureMessage(`Search glossary Error: ${e}`, 'error');
      return appMessageHandling(
        dispatch,
        e?.response?.data?.detail ?? e,
        'error'
      );
    } finally {
      dispatch(stopLoading());
    }
  };

export const searchAIGlossery =
  (data: { word: string; email: string }) => async (dispatch: any) => {
    try {
      dispatch(loading());
      dispatch(
        emptyStateValueGlossary({
          AIWordExplanation: [],
          selectedTagsList: null,
          difficultWords: [],
          difficultWordsToShow: undefined,
          otherWordExplanation: undefined,
          hasCopyRestriction: undefined,
        })
      );
      const resDtails: any = await glossaryService.searchAIGlossaryApi(data);
      dispatch({
        type: AI_WORD_EXPLANATION,
        payload: [{ ...resDtails?.data, copyRestriction: 0 }],
      });
    } catch (e: any) {
      Sentry.captureMessage(`Search AI glossary Error: ${e}`, 'error');
      if (e.response && e.response.status === 401) {
        return dispatch(showAiSearchUnauthAlert(true));
      }
      return appMessageHandling(
        dispatch,
        e?.response?.data?.detail ?? e,
        'error'
      );
    } finally {
      dispatch(stopLoading());
    }
  };

export const getGlossaryTags = () => async (dispatch: any) => {
  try {
    dispatch(loading());
    dispatch(
      emptyStateValueGlossary({
        glossaryTags: [],
        selectedTagsList: null,
        difficultWords: [],
        difficultWordsToShow: undefined,
        otherWordExplanation: undefined,
        hasCopyRestriction: undefined,
      })
    );
    const resDtails: any = await glossaryService.getGlossaryTagsApi();

    dispatch({
      type: GET_GLOSSARY_TAGS,
      payload: resDtails?.data.tags,
    });
  } catch (e: any) {
    Sentry.captureMessage(`Get glossary tags Error: ${e}`, 'error');
    return appMessageHandling(
      dispatch,
      e?.response?.data?.detail ?? e,
      'error'
    );
  } finally {
    dispatch(stopLoading());
  }
};

export const createNewGlossary =
  (data: GlossaryData) => async (dispatch: any, state: any) => {
    try {
      dispatch(loading());
      const resDtails: any = await glossaryService.createNewGlossaryApi(data);

      dispatch({
        type: CREATE_GLOSSARY,
        payload: resDtails?.data,
      });
      appMessageHandling(dispatch, 'success.createGlossarySuccess', 'success');
    } catch (e: any) {
      //  });
      Sentry.captureMessage(`Create new glossary Error: ${e}`, 'error');
      return appMessageHandling(dispatch, 'error.createGlossaryError', 'error');
    } finally {
      dispatch(stopLoading());
    }
  };

export const deleteGlossary =
  (id: string) => async (dispatch: any, state: any) => {
    try {
      dispatch(loading());
      await glossaryService.deleteGlossaryApi(id);

      dispatch({
        type: DELETE_GLOSSARY,
        payload: id,
      });

      appMessageHandling(dispatch, 'success.deletGlossarySuccess', 'success');
      // let selectedTagsList = state().glossary?.selectedTagsList;
      // let difficultWords = state().glossary?.glossaryItems?.difficultWords;
      // dispatch(setDifficultWordsToShow(difficultWords, selectedTagsList));
    } catch (e: any) {
      Sentry.captureMessage(`Delete glossary Error: ${e}`, 'error');
      return appMessageHandling(
        dispatch,
        e?.response?.data?.detail ?? e,
        'error'
      );
    } finally {
      dispatch(stopLoading());
    }
  };

export const loading = () => (dispatch: any) => {
  return dispatch({
    type: LOADING,
  });
};

export const stopLoading = () => (dispatch: any) => {
  return dispatch({
    type: STOP_LOADING,
  });
};

export const showAiSearchUnauthAlert = (data: boolean) => (dispatch: any) => {
  return dispatch({
    type: SHOW_AI_SEARCH_UNAUTH_ALERT,
    payload: data,
  });
};

export const emptyStateValueGlossary =
  (value: OptionalGlossaryReducers) => (dispatch: any) => {
    return dispatch({
      type: EMPTY_STATE_VALUE,
      payload: value,
    });
  };

// Created a taged list for selected tags
export const setSelectedTagsList = (value: string) => (dispatch: any) => {
  dispatch({
    type: SELECTED_TAGS_LIST,
    payload: typeof value === 'string' ? value.split(',') : value,
  });
};

export const setShowGlossaryCopyRestrictionAlert =
  (value: boolean) => (dispatch: any) => {
    return dispatch({
      type: SHOW_GLOSSARY_COPY_RESTRICTION_ALERT,
      payload: value,
    });
  };

export const setDifficultWordsToShow =
  (words: any, selectedTagsList?: []) => (dispatch: any) => {
    const payload = __filterWordsByTags(words, selectedTagsList);
    return dispatch({
      type: DIFFICULT_WORDS_TO_SHOW,
      payload,
    });
  };

export const setDifficultWords = (value: any) => (dispatch: any) => {
  dispatch({
    type: DIFFICULT_WORDS,
    payload: value,
  });
};

export const ownExplanations = (data?: any) => (dispatch: any, state: any) => {
  let selectedTagsList = state().glossary?.selectedTagsList;
  let difficultWords = state().glossary?.glossaryItems?.difficultWords;
  let userEmail = state().supabase?.user?.email;
  if (difficultWords) {
    const hasCopyRestriction = Object.values(difficultWords)?.some(
      (glossaryEntry: any) => glossaryEntry.copyRestriction === 1
    );
    dispatch(setShowGlossaryCopyRestrictionAlert(hasCopyRestriction));

    // map source to explanations
    let otherExplanationsWithSources: any = {};
    let ownExplanations: any = [];
    let order: { [key: string]: number } = {
      'summ-ai.com': 0,
      'bayern.landtag.de': 1,
      hurraki: 2,
      'corona-leichte-sprache': 3,
    };
    Object.values(difficultWords)?.forEach((wordEntry: any, i: number) => {
      // extract own explanations
      if (wordEntry['source'] === userEmail) {
        ownExplanations.push(wordEntry);
      } else {
        let orderKey = order[wordEntry['source']];
        // all the rest of explanations with source
        if (orderKey in otherExplanationsWithSources) {
          otherExplanationsWithSources[orderKey] =
            otherExplanationsWithSources[orderKey].concat(wordEntry);
        } else {
          otherExplanationsWithSources[orderKey] = [wordEntry];
        }
      }
    });
    dispatch(setDifficultWordsToShow(ownExplanations, selectedTagsList));

    dispatch(setDifficultWords(ownExplanations));

    dispatch({
      type: OWN_EXPLANATIONS,
      payload: otherExplanationsWithSources,
    });
  }
};

const __filterWordsByTags = (
  words: DifficultWords[],
  selectedTagsList?: string[]
) => {
  if (selectedTagsList && words && selectedTagsList.length !== 0) {
    let wordsToShow: any = [];
    for (let wordEntry of words) {
      if (wordEntry['tags'] !== undefined && wordEntry['tags'] !== null) {
        if (
          // check selected tag list includes only tags from word entry
          selectedTagsList.every((r) =>
            wordEntry['tags']?.split(',').includes(r)
          )
        ) {
          wordsToShow = [
            ...wordsToShow.filter((word: any) => word.word !== wordEntry.word),
            wordEntry,
          ];
        }
      }
    }
    return wordsToShow;
  } else {
    return words;
  }
};

const __hasCopyRestriction = (glossary: DifficultWords[]) => {
  return glossary.some((glossaryEntry) => glossaryEntry.copyRestriction === 1);
};
