import { Form, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { InfoSquareFill } from 'react-bootstrap-icons';
import styles from './TranslationInput.module.css';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

type Props = {};

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip
    {...props}
    classes={{ popper: className }}
  />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 600,
    fontSize: '.8rem',
  },
});

const TranslationInputHeader = (props: Props) => {
  const { t } = useTranslation(['easy_language', 'common'], {
    nsMode: 'fallback',
  });

  return (
    <Row className="mb-1">
      <Col className={styles['tooltip-col']}>
        <CustomWidthTooltip
          placement="bottom-end"
          className={styles['translation-textarea-infobox']}
          id="info-header-input"
          title={
            <>
              <div
                className="text-left"
                dangerouslySetInnerHTML={{
                  __html: t('originaltext.warning', {
                    interpolation: { escapeValue: false },
                  }),
                }}
              />
            </>
          }
        >
          <InfoSquareFill className="text-white" />
        </CustomWidthTooltip>
      </Col>
      <Col
        style={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        <Form.Label className={'text-light ' + styles['language-label']}>
          {t('originaltext.label')}
        </Form.Label>
      </Col>
      <Col></Col>
    </Row>
  );
};

export default TranslationInputHeader;
