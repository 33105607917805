import React, { Ref } from 'react';
import TextEditorComp from 'src/coreUI/textEditorComp/TextEditorComp';
import DragAndDropField from './DragAndDropField';
import Grid from '@mui/material/Grid';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';
import { useSelector, shallowEqual } from 'react-redux';
import { UserSelector } from 'src/redux/user/selector';
import { TranslateSelector } from 'src/redux/translation/selector';

type Props = {
  switchInput: (value: boolean) => void;
  fileInput: boolean;
  setInputText: React.Dispatch<React.SetStateAction<string>>;
  editorValue: (text: string, refEditor: Ref<any>) => void;
  inputRefEditor: Ref<any>;
  disableInputField: boolean;
  inputText: string;
  inputTextValue: string;
};

const InputTextFileComp = ({
  switchInput,
  fileInput,
  setInputText,
  editorValue,
  inputRefEditor,
  disableInputField,
  inputText,
  inputTextValue,
}: Props) => {
  const { t } = useTranslation(['easy_language', 'common'], {
    nsMode: 'fallback',
  });

  const hasGroup = useSelector(
    (state: any) => UserSelector(state).hasGroup,
    shallowEqual
  );

  const transjob = useSelector(
    (state: any) => TranslateSelector(state).transjob,
    shallowEqual
  );

  const userDetails = useSelector(
    (state: any) => UserSelector(state).userDetails,
    shallowEqual
  );
  return (
    <Grid
      item
      container
      xs={12}
      sx={{ position: 'relative' }}
    >
      <Grid
        item
        xs={12}
        sx={{
          height: '95.5%',
          width: '100%',
          zIndex: fileInput ? 2 : 1,
          position: 'absolute',
          visibility: fileInput ? 'visible ' : 'hidden',
        }}
      >
        <DragAndDropField
          setInputText={setInputText}
          switchInput={switchInput}
          editorValue={editorValue}
          inputRefEditor={inputRefEditor}
        />
      </Grid>
      <Tooltip
        title={hasGroup ? undefined : t('output.hasNoGroup')}
        placement="top"
      >
        <Grid
          item
          xs={12}
          sx={{
            height: '100%',
            zIndex: fileInput ? 1 : 2,
            visibility: fileInput ? 'hidden' : 'visible',
          }}
        >
          <TextEditorComp
            disableInputField={disableInputField}
            inputText={inputText}
            textValue={inputTextValue}
            refEditor={inputRefEditor}
            placeholder={t('originaltext.textarea.placeholder')}
            showCounter={true}
            counterTitle={t('charactercount')}
            id={'translation-input'}
            showToolbar={true}
            showSwitch={true}
            editable={hasGroup}
            complexityScore={
              false && userDetails?.previewFeatures && transjob?.text_metrics
                ? `${Math.round(transjob.text_metrics.input_lix_score)} (${t(
                    transjob.text_metrics.input_lix_score_display
                  )})`
                : ''
            }
            complexityScoreTitle={t('complexityScoreTitle')}
            comlexityScoreDescription={t('complexityInfo')}
            displayImage={false}
          />
        </Grid>
      </Tooltip>
    </Grid>
  );
};

export default InputTextFileComp;
