import React, {FC} from 'react';
import {Button, Modal} from 'react-bootstrap';
import styles from './WhatsnewPopup.module.css';
import {useTranslation} from 'react-i18next';

type Props = {
  show: boolean;
  close: (show: boolean) => void;
  onClick: () => void;
};

const WhatsnewPopup: FC<Props> = (props: Props) => {
  const {t} = useTranslation('dashboard');

  return (
    <Modal
      show={props.show}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      onHide={() => props.close(true)}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Header closeButton>
        <Modal.Title>{t('whatsnew.title')}</Modal.Title>
      </Modal.Header>
      <Modal.Body className={styles['modal-body-div']}>
        <div dangerouslySetInnerHTML={{__html: t('whatsnew.text')}} />
      </Modal.Body>
      <Modal.Footer>
        <Button
          id="closeWhatsnewPopup"
          onClick={props.onClick}
        >
          Schließen
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default WhatsnewPopup;
