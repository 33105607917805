import userService from '../../services/user.service';
import { appMessageHandling } from '../../utils/errorHandler';
import {
  CREATE_USER_API_KEY,
  DELETE_USER_API_KEY,
  EMPTY_STATE_VALUE,
  GET_USER_API_KEY,
  GET_USER_DETAILS,
  LOADING,
  // NEW_USER_SIGNUP,
  REST_CREATED_API_KEY,
  STOP_LOADING,
  UPDATE_QUOTA_LIMIT,
  UPDATE_QUOTA_SPENT,
  UPDATE_USER_API_KEY,
  UPDATE_USER_DETAILS,
  UPDATE_USER_DETAILS_API,
  USER_EXIST_CHECK,
} from './types';
import Sentry from '../../config/sentryConfig';
// import { separateStyleTrigger } from '../translation/actions';
import { SeparatorValue } from 'src/types/translation.types';
// import { separateStyleTrigger } from '../translation/actions';
import { UserReducers } from './selector';

export const getUserDetails = () => async (dispatch: any, getState: any) => {
  // const transSeparator = getState().translation.separatorStyle;
  try {
    dispatch(loading());
    const [resDtails, resQuota]: {
      data: { preferredstyle: SeparatorValue };
    }[] = await Promise.all([
      await userService.getUserDetailsApi(),
      await userService.getUserQuotaApi(),
    ]);
    localStorage.removeItem('signup_form');
    dispatch({
      type: GET_USER_DETAILS,
      payload: { ...(resDtails?.data ?? {}), ...(resQuota?.data ?? {}) },
    });
    // if (transSeparator !== resDtails.data.preferredstyle) {
    //   dispatch(separateStyleTrigger(resDtails.data.preferredstyle));
    // }
  } catch (e: any) {
    Sentry.captureMessage(`Get User Data Error: ${e}`, 'error');

    // check user already signup or not
    if (
      ['User does not exist', 'User did not finish signup'].includes(
        e.response.data.detail
      )
    ) {
      localStorage.setItem('signup_form', 'true');
      dispatch(updateUserExist(e.response.data.detail));
      window.location.href = '/signup';
    }

    // return appMessageHandling(dispatch, 'error.get_user_details', 'error');
  } finally {
    dispatch(stopLoading());
  }
};

export const createUserApiKey = (name: string) => async (dispatch: any) => {
  try {
    dispatch(loading());
    const res = await userService.createUserKeyApi(name);

    dispatch({
      type: CREATE_USER_API_KEY,
      payload: res.data,
    });
  } catch (e: any) {
    Sentry.captureMessage(`Create User Api Key Error: ${e}`, 'error');
    return appMessageHandling(dispatch, e.response.data.detail, 'error');
  } finally {
    dispatch(stopLoading());
  }
};

export const getUserApiKey = () => async (dispatch: any) => {
  try {
    dispatch(loading());
    const res = await userService.getUserKeyApi();
    dispatch({
      type: GET_USER_API_KEY,
      payload: res.data,
    });
  } catch (e: any) {
    Sentry.captureMessage(`Get User Api Key Error: ${e}`, 'error');
    return appMessageHandling(dispatch, 'error.get_user_details', 'error');
  } finally {
    dispatch(stopLoading());
  }
};

export const deleteUserApiKey = (prefix: string) => async (dispatch: any) => {
  try {
    dispatch(loading());
    await userService.deleteUserKeyApi(prefix);
    dispatch({
      type: DELETE_USER_API_KEY,
      payload: prefix,
    });
  } catch (e: any) {
    Sentry.captureMessage(`Delete User Api Key Error: ${e}`, 'error');
    return appMessageHandling(dispatch, e.response.data.detail, 'error');
  } finally {
    dispatch(stopLoading());
  }
};

export const updateUserApiKey =
  (prefix: string, name: string) => async (dispatch: any) => {
    try {
      dispatch(loading());
      const res = await userService.updateUserKeyApi(prefix, name);

      dispatch({
        type: UPDATE_USER_API_KEY,
        payload: res.data,
      });
    } catch (e: any) {
      Sentry.captureMessage(`Update User Api Key Error: ${e}`, 'error');
      return appMessageHandling(dispatch, e.response.data.detail, 'error');
    } finally {
      dispatch(stopLoading());
    }
  };

export const updateUserInfo =
  (data: { hasreadwhatsnew: boolean }) => async (dispatch: any) => {
    try {
      dispatch(loading());
      const res = await userService.updateUserDetailsApi(data);
      dispatch({
        type: UPDATE_USER_DETAILS_API,
        payload: res.data,
      });
    } catch (e: any) {
      Sentry.captureMessage(`Update User Info Error: ${e}`, 'error');
      return appMessageHandling(dispatch, e.response.data.detail, 'error');
    } finally {
      dispatch(stopLoading());
    }
  };

export const newUserSignup = (data: object) => async (dispatch: any) => {
  try {
    dispatch(loading());
    await userService.newUserSignupApi(data);
    await dispatch(getUserDetails());
    window.location.href = '/dashboard';
  } catch (e: any) {
    Sentry.captureMessage(`New user signup Error: ${e}`, 'error');
    return appMessageHandling(dispatch, 'error.notCreatedError', 'error');
  } finally {
    dispatch(stopLoading());
  }
};

export const loading = () => (dispatch: any) => {
  return dispatch({
    type: LOADING,
  });
};

export const stopLoading = () => (dispatch: any) => {
  return dispatch({
    type: STOP_LOADING,
  });
};

// No api call
export const updateUserDetails = (data: any) => (dispatch: any) => {
  return dispatch({
    type: UPDATE_USER_DETAILS,
    payload: data,
  });
};

export const resetCreatedApiKey = () => (dispatch: any) => {
  return dispatch({
    type: REST_CREATED_API_KEY,
  });
};

export const setQuotaLimit = (quota: number) => (dispatch: any) => {
  return dispatch({
    type: UPDATE_QUOTA_LIMIT,
    payload: quota,
  });
};

export const setQuotSpent = (quota: number) => (dispatch: any) => {
  return dispatch({
    type: UPDATE_QUOTA_SPENT,
    payload: quota,
  });
};

export const emptyStateValueUser = (value: UserReducers) => (dispatch: any) => {
  return dispatch({
    type: EMPTY_STATE_VALUE,
    payload: value,
  });
};

export const updateUserExist = (value: string) => (dispatch: any) => {
  return dispatch({
    type: USER_EXIST_CHECK,
    payload: ['User does not exist', 'User did not finish signup'].includes(
      value
    )
      ? false
      : true,
  });
};
