import translationService from 'src/services/translation.service';
import { appMessageHandling } from '../../utils/errorHandler';
import {
  UPDATE_JOB_STATUS,
  TRANSLATE_LANG,
  SWITCH_SEPARATOR,
  NEW_LINE_SET,
  LOADING_TRANS,
  STOP_LOADING_TRANS,
  EMPTY_STATE_VALUE,
  SET_TRANS_CONFIG,
  GET_TRANS_CONFIG,
  UPDATE_TEXT_VALUE,
  UPDATE_SELECTED_OUTPUT_NODE,
  UPDATE_EMPTY_INPUT_VALUE,
} from './types';
import {
  SeparatorValue,
  TranslateLang,
  UpdateTransJob,
  transConfigGet,
} from 'src/types/translation.types';
import { AxiosResponse } from 'axios';
import Sentry from '../../config/sentryConfig';
import { setQuotSpent, setQuotaLimit } from '../user/actions';
import { updateJobValue } from '../translationHistory/actions';
import { updateTableColumn } from 'src/components/TextareaField/helper';
import { outputEditor } from 'src/components/TextareaField/TextareaField';

export const updateTransJob =
  (jobId: string | number, data: UpdateTransJob) => async (dispatch: any) => {
    try {
      await translationService.updateTranslationJob(jobId, data);
      dispatch({
        type: UPDATE_JOB_STATUS,
        // payload: res,
      });
      // return appMessageHandling(
      //   dispatch,
      //   'success.job_update_success',
      //   'success'
      // );
    } catch (e: any) {
      return appMessageHandling(dispatch, 'error.job_update_error', 'error');
    }
  };

export const translateLangTrigger =
  (data: TranslateLang) => async (dispatch: any, getState: any) => {
    let stateValue = getState().user.userDetails;
    let inputText = data.orignalInputText;
    delete data.orignalInputText;
    try {
      dispatch(loading());
      dispatch(emptyStateValueTrans({ transjob: { jobId: '-1' } }));
      const res: AxiosResponse<
        TranslateLang & {
          jobid?: string;
          quotalimit?: number;
          quotaspent?: number;
        }
      > = await translationService.transaltionLag(data);
      if (!res.data || res.data.jobStatus === 'error') {
        throw res.data;
      }
      const { jobid, quotalimit, quotaspent } = res.data;
      localStorage.setItem('jobId', jobid || '');

      let showModal = false;
      if (stateValue?.translatorlicence === true) {
        if (
          quotalimit &&
          quotaspent &&
          (quotalimit - quotalimit / 2 === quotaspent ||
            quotalimit - 3000 === quotaspent)
        ) {
          dispatch(setQuotaLimit(quotalimit));
          dispatch(setQuotSpent(quotaspent));
          showModal = true;
        }
      }
      // dispatch(
      //   updateJobValue({
      //     ...res.data,
      //     originaltext: inputText,
      //     simpletext: res.data.simpleText,
      //     simpletextuseredited: null,
      //     id: res.data.jobid,
      //   })
      // );
      dispatch({
        type: TRANSLATE_LANG,
        payload: { ...res.data, showModal, inputText: inputText },
      });

      // return appMessageHandling(
      //   dispatch,
      //   'success.translate_job_success',
      //   'success'
      // );
    } catch (e: any) {
      if (e.hasOwnProperty('jobid')) {
        dispatch(
          updateTransJob(e.jobid, {
            jobstatus: 'frontend error: ' + e.message,
          })
        );
      }
      appMessageHandling(
        dispatch,
        e?.response?.data?.detail ?? 'error.translate_job_error',
        'error'
      );
      Sentry.captureMessage(`Translation job create Error: ${e}`, 'error');
    } finally {
      dispatch(stopLoading());
    }
  };

export const separateStyleTrigger =
  (value: SeparatorValue) => async (dispatch: any) => {
    try {
      const elementSelect: any = document.querySelector('#translation-output');
      await translationService.separateStyle({
        input_text: elementSelect?.innerText ?? '',
        separator_style: value,
      });
      dispatch({
        type: SWITCH_SEPARATOR,
        payload: value,
      });
    } catch (e: any) {
      return appMessageHandling(dispatch, 'error.set_separate__error', 'error');
    }
  };

export const getTranslationConfig = () => async (dispatch: any) => {
  try {
    const res: AxiosResponse<transConfigGet> =
      await translationService.getTransConfig();
    dispatch({
      type: GET_TRANS_CONFIG,
      payload: res.data,
    });
  } catch (e: any) {
    return appMessageHandling(dispatch, 'error.get_config__error', 'error');
  }
};

export const setTranslationConfig =
  (data: transConfigGet) => async (dispatch: any) => {
    try {
      const res: AxiosResponse<transConfigGet> =
        await translationService.updateTransConfig(data);
      dispatch({
        type: SET_TRANS_CONFIG,
        payload: res.data,
      });
    } catch (e: any) {
      return appMessageHandling(dispatch, 'error.set_config__error', 'error');
    }
  };

export const newLineSet = (value: boolean) => async (dispatch: any) => {
  try {
    localStorage.setItem('isNewLine', `${value}`);
    dispatch({
      type: NEW_LINE_SET,
      payload: value,
    });
  } catch (e: any) {
    return appMessageHandling(dispatch, 'error.set_newLine__error', 'error');
  }
};

export const updateTranlationText =
  (data: { input_text: string; separator: string }) =>
  async (dispatch: any) => {
    try {
      let res = await translationService.updateTextOutput(data);
      dispatch({
        type: UPDATE_TEXT_VALUE,
        payload: res.data,
      });
    } catch (e: any) {
      Sentry.captureMessage(
        `Update translation text when separator update Error: ${e}`,
        'error'
      );
    }
  };

export const loading = () => (dispatch: any) => {
  return dispatch({
    type: LOADING_TRANS,
  });
};

export const stopLoading = () => (dispatch: any) => {
  return dispatch({
    type: STOP_LOADING_TRANS,
  });
};

export const emptyStateValueTrans = (data: any) => (dispatch: any) => {
  return dispatch({
    type: EMPTY_STATE_VALUE,
    payload: data,
  });
};

export const updateSelectedOutputNode =
  (data: string | null) => (dispatch: any) => {
    return dispatch({
      type: UPDATE_SELECTED_OUTPUT_NODE,
      payload: data,
    });
  };

export const insertImageToTable =
  (url: string) => (dispatch: any, getState: any) => {
    const selectedNode = getState().translation.selectedOutputNode;
    if (selectedNode && outputEditor) {
      updateTableColumn(outputEditor, url, selectedNode);
      // return dispatch(updateSelectedOutputNode(null));
    }
  };

export const updateEmptyInputValue = (value: boolean) => (dispatch: any) => {
  return dispatch({
    type: UPDATE_EMPTY_INPUT_VALUE,
    payload: value,
  });
};
