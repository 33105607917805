import React, { useContext, useState, useEffect } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { supabase } from 'src/config/supabase';
import {
  getSessionAction,
  updateTokenAction,
  updateUserAction,
} from 'src/redux/supabase/actions';
import { supabaseSelector } from 'src/redux/supabase/selector';
import { getUserDetails } from 'src/redux/user/actions';
import { UserSelector } from 'src/redux/user/selector';
import { clearLocalSessionStorage } from 'src/utils/clearStorage';

export type AuthContextType = {};

const AuthContext = React.createContext<AuthContextType | null>(null);

export function useAuth() {
  return useContext(AuthContext) as AuthContextType;
}

type Props = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: Props) {
  const [loading, setLoading] = useState(false);
  const dispatch: any = useDispatch();
  const history = useNavigate();

  const supabaseSession = useSelector(
    (state: any) => supabaseSelector(state).session,
    shallowEqual
  );

  const supabaseUser = useSelector(
    (state: any) => supabaseSelector(state).user,
    shallowEqual
  );

  const userDetails = useSelector(
    (state: any) => UserSelector(state).userDetails,
    shallowEqual
  );

  useEffect(() => {
    dispatch(getSessionAction());
    const unsubscribe = supabase.auth.onAuthStateChange(
      async (event, session) => {
        // remove the password rest type from local session
        if (['USER_UPDATED'].includes(event) && session) {
          dispatch(updateTokenAction(session));
          clearLocalSessionStorage('type');
          history('/');
        }
        let seesionStorageType = sessionStorage.getItem('type');
        if (event !== 'PASSWORD_RECOVERY' && !seesionStorageType && session) {
          if (
            (event === 'TOKEN_REFRESHED' || event === 'SIGNED_IN') &&
            session
          ) {
            if (
              !supabaseSession ||
              session?.access_token !== supabaseSession?.access_token ||
              session?.refresh_token !== supabaseSession?.refresh_token
            ) {
              dispatch(updateTokenAction(session));
            }
            if (!userDetails && !localStorage.getItem('signup_form')) {
              dispatch(getUserDetails());
            }

            if (!supabaseUser) {
              dispatch(updateUserAction(session.user));
            }
          }
          // do not refresh on tab switch (https://github.com/supabase/supabase-js/issues/442)
          if (
            !(
              (event === 'SIGNED_IN' || event === 'SIGNED_OUT') &&
              !supabaseSession &&
              session
            )
          ) {
            return;
          } else {
            if (!supabaseUser) {
              dispatch(updateUserAction(session.user));
            }
            setLoading(false);
            // set beta false and only change when user is validated with request below
          }

          // get jwt
          if (
            session?.access_token &&
            (session?.access_token !== supabaseSession?.access_token ||
              !supabaseSession)
          ) {
            dispatch(updateTokenAction(session));

            if (!session?.access_token) {
              return;
            }
          }
        }
      }
    );
    // check beta access
    return unsubscribe.data.subscription.unsubscribe;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const value: AuthContextType | null = null;

  return (
    <AuthContext.Provider value={value}>
      {!loading && children}
    </AuthContext.Provider>
  );
}
