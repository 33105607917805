// import Link from '@mui/material/Link';
import { Link } from 'react-router-dom';
import LogoutDropdownItem from '../UserAccount/LogoutDropdownItem';
import UserIcon from '../UserAccount/UserIcon';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

export type Pages = {
  id: string;
  link: string;
  text: string;
  featureId?: number;
};
export const pages: (preview: boolean) => (Pages | null)[] = (
  preview: boolean
) => [
  {
    id: 'N1',
    link: '/dashboard',
    text: 'nav.dashboard',
  },
  {
    id: 'N2',
    link: '/easy-language',
    text: 'nav.newTranslation',
  },
  {
    id: 'N3',
    link: '/translation-history',
    text: 'nav.translationHistory',
  },
  {
    id: 'N4',
    link: '/glossary-entries',
    text: 'nav.glossaryEntries',
  },

  {
    id: 'N5',
    link: '/my-gallery',
    text: 'nav.myGallery',
    featureId: 6,
  },
  // {
  //   id: 'N6',
  //   link: '/whats-new',
  //   text: 'nav.whatsNew',
  // },
];

export const profileMenu = {
  list: [
    {
      id: 'pm1',
      value: (
        <Link
          to="/account/main"
          style={{
            textDecoration: 'none',
            padding: '6px 8px',
            justifyContent: 'center',
          }}
        >
          Account
        </Link>
      ),
    },
    {
      id: 'pm2',
      value: (
        <LogoutDropdownItem
          buttonStyle={{
            padding: '6px 8px',
            fontSize: '1rem',
            '&:hover': {
              background: 'none',
            },
          }}
        />
      ),
    },
  ],
  menuView: <UserIcon />,
  title: 'User Profile',
};

export const languageMenu = {
  list: (handleClick: (lang: 'de' | 'fr' | 'en') => void) => [
    {
      id: 'lm1',
      value: (
        <Button
          variant="text"
          onClick={() => handleClick('de')}
          sx={{
            textTransform: 'capitalize',
            padding: 0,
          }}
          data-testid="DE"
        >
          {' '}
          DE
        </Button>
      ),
    },
    {
      id: 'lm2',
      value: (
        <Button
          variant="text"
          onClick={() => handleClick('en')}
          sx={{
            textTransform: 'capitalize',
            padding: 0,
          }}
          data-testid="EN"
        >
          {' '}
          EN
        </Button>
      ),
    },
    {
      id: 'lm3',
      value: (
        <Button
          variant="text"
          onClick={() => handleClick('fr')}
          sx={{
            textTransform: 'capitalize',
            padding: 0,
          }}
          data-testid="FR"
        >
          {' '}
          FR
        </Button>
      ),
    },
  ],
  menuView: (menuView: string) => (
    <Typography
      variant="body2"
      gutterBottom
      sx={{ marginBottom: 0 }}
    >
      {menuView}
    </Typography>
  ),
  title: '',
};
