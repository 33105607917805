// import { Link } from 'react-router-dom';
import { PersonCircle } from 'react-bootstrap-icons';
import theme from '../../theme/themeConfig';

const UserIcon = () => {
  return (
    // <Link to="">
    <PersonCircle
      size={35}
      color={theme.palette.primary.main}
    />
    // </Link>
  );
};

export default UserIcon;
