import { VisibilityOff, Visibility } from '@mui/icons-material'
import { InputLabel, OutlinedInput, InputAdornment, IconButton ,FormControl} from '@mui/material'
import React from 'react'

type Props = {
    value: string
    handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void
    label:string
    name:string,
    style?:object
}

const PasswordInput = ({value, handleChange,label,name,style={}}: Props) => {
    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);
  
    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
      event.preventDefault();
    };


  return (
    <FormControl sx={{ m: 1,...style}} variant="outlined">
    <InputLabel htmlFor="outlined-adornment-password">{label}</InputLabel>
    <OutlinedInput
      id={`outlined-adornment-password-${label}`}
      type={showPassword ? 'text' : 'password'}
      onChange={handleChange}
      name={name}
      endAdornment={
        <InputAdornment position="end">
          <IconButton
            aria-label="toggle password visibility"
            onClick={handleClickShowPassword}
            onMouseDown={handleMouseDownPassword}
            edge="end"
          >
            {showPassword ? <VisibilityOff /> : <Visibility />}
          </IconButton>
        </InputAdornment>
      }
      label={label}
      value={value}
    />
  </FormControl>
  )
}

export default PasswordInput