import React from 'react';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import { styled } from '@mui/material';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion
    disableGutters
    elevation={0}
    square
    {...props}
  />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  // borderRadius: '5px',
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    {...props}
    expandIcon={props.expandIcon}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
}));

type Props = {
  id?: string;
  accordionSummary: React.ReactNode | string;
  summaryStyle?: object;
  accordionStyle?: object;
  disabled?: boolean;
  expandIcon?: React.ReactNode;
  firstItem?: boolean;
  lastItem?: boolean;
};
const ItemView = ({
  id = 'panel2-header',
  accordionSummary,
  summaryStyle = {},
  accordionStyle = {},
  disabled,
  expandIcon,
  firstItem = false,
  lastItem = false,
}: Props) => {
  return (
    <Accordion
      expanded={false}
      disabled={disabled}
      disableGutters
      sx={{
        boxShadow: 'none',
        borderRadius:
          lastItem && firstItem
            ? '4px 4px 4px 4px'
            : lastItem
            ? '0px 0px 4px 4px'
            : firstItem
            ? '4px 4px 0px 0px'
            : 0,
        '& .Mui-expanded': {
          transform: 'rotate(0deg)',
        },
        ...accordionStyle,
      }}
    >
      <AccordionSummary
        expandIcon={expandIcon}
        aria-controls={id}
        id={id}
        sx={{
          height: '37px',
          minHeight: '37px',
          backgroundColor: 'rgba(0, 0, 0, .03)',
          '& .MuiAccordionSummary-content': {
            m: 0,
          },
          '& .MuiIconButton-root': {
            pr: '5px',
          },
          ...summaryStyle,
        }}
      >
        {accordionSummary}
      </AccordionSummary>
    </Accordion>
  );
};

export default ItemView;
