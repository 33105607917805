import { useGridApiContext } from '@mui/x-data-grid';
import React, { SetStateAction } from 'react';
import { Form } from 'react-bootstrap';
import { FixMe } from 'src/types/types';

type Props = {
  params: FixMe;
  setActiveId: React.Dispatch<SetStateAction<string>>;
  activeId: string | null;
  headerName: string;
  id?: string;
};

const GlossaryTextArea = ({
  params,
  setActiveId,
  activeId,
  headerName,
  id,
}: Props) => {
  const apiRef = useGridApiContext();
  const handleClick = () => {
    apiRef.current.startCellEditMode({ id: params.id, field: params.field });
    setActiveId(params.id);
  };

  return (
    <Form.Group style={{ width: '100%' }}>
      <Form.Control
        as="textarea"
        defaultValue={params.value}
        rows={params.row.id === activeId ? 10 : 2}
        style={{ resize: 'none' }}
        onClick={handleClick}
        aria-label={`${headerName}`}
        id={id}
      />
    </Form.Group>
  );
};

export default GlossaryTextArea;
